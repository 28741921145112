import styled from 'styled-components'
import React, { useState } from 'react'
import { useSetUserConsentMutation } from '../../operations/mutations/setUserConsent'
import nemliatalvalogo from '../../images/nemliatalvalogo.png'
import { breakpoints } from '../../utils/breakpoints'
import { useTranslation } from 'react-i18next/'

interface Props {
  user: Api.User
}

const Consent = ({ user }: Props) => {
  const { t, i18n } = useTranslation()

  var userLanguage = i18n.language

  return (
    <div>
      <ImageStyle src={nemliatalvalogo} alt="Nemlia Talva" />
      <ConsentContainer>
        <ConsentText>
          <h2>{t('consent.consent')}</h2>
          <p>{t('consent.please_read')}</p>
          <p>
            <br />

            <a
              href={getLink(userLanguage)}
              target="_blank"
              rel="noopener noreferrer"
            >
              {getLink(userLanguage)}
            </a>
            <br />
            <br />
          </p>

          <ButtonAgree user={user} />
        </ConsentText>
      </ConsentContainer>
    </div>
  )
}

interface ButtonAgreeProps {
  user: Api.User
}

const ButtonAgree = ({ user }: ButtonAgreeProps) => {
  const { t } = useTranslation()

  const { mutate: setUserConsent } = useSetUserConsentMutation(() => {
    console.log('Consent agreed')
  })

  const [checked, setChecked] = useState(false)
  const handleChange = () => {
    setChecked(!checked)
  }

  return (
    <>
      <label>
        <input type="checkbox" checked={checked} onChange={handleChange} />
        {t('consent.i_have_read')}
      </label>
      <br />
      <Button
        disabled={!checked}
        onClick={() => {
          setUserConsent({
            variables: { email: user.email, consent_accepted: true },
          })
        }}
        style={{
          opacity: checked ? '1' : '0.3',
        }}
      >
        {t('consent.agree')}
      </Button>
    </>
  )
}

export default Consent

function getLink(lang: string) {
  let link = ''

  switch (lang) {
    case 'fo':
      link = 'https://nemlia.com/consent/'
      break
    case 'de':
      link = 'https://nemlia.com/de/consent/'
      break
    case 'da':
      link = 'https://nemlia.com/da/consent/'
      break
    default:
      link = 'https://nemlia.com/en/consent/'
      break
  }

  return link
}

const ConsentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const ConsentText = styled.div`
  background: #fff;
  width: 90%;
  padding: 20px;
  margin-top: 30px;
  border-radius: 10px;
  @media (min-width: 768px) {
    width: 70%;
    padding: 30px;
  }
`
const Button = styled.button`
  color: white;
  border: 0;
  background: #50af9f;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  line-height: 1;
  width: 100%;
  margin-top: 20px;
  max-width: 300px;
  cursor: pointer;
  &:hover {
    background: #50af9fd9;
  }
`
const ImageStyle = styled.img`
  width: 160px;
  margin-top: 30px;
  margin-left: 20px;
  @media (min-width: ${breakpoints.small}px) {
    width: auto;
    margin-bottom: 0;
    margin: 35px;
  }
`
