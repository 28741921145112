import React from 'react'
import styled from 'styled-components'
import { breakpoints } from '../../utils/breakpoints'
import Measure from '../measure/Measure'
import Locate from '../locate/Locate'
import Footer from '../footer/Footer'
import { MainContainer } from '../../styles/global'
import { MainContext } from '../../MainContext'

interface Props {
  currentUser: Api.User
}

export default function Dashboard({ currentUser }: Props) {
  return (
    <>
      <MainContainer>
        <MainContext.Consumer>
          {({ currentCitizen }) => (
            <WidgetsContainer>
              {currentUser?.show_locate &&
                (currentCitizen.ta_trackers[0] ? (
                  <WidgetColumn fluid={!currentUser?.show_measure}>
                    <Locate />
                  </WidgetColumn>
                ) : null)}

              {currentUser?.show_measure && (
                <WidgetColumn fluid>
                  <Measure />
                </WidgetColumn>
              )}
            </WidgetsContainer>
          )}
        </MainContext.Consumer>
      </MainContainer>
      <Footer />
    </>
  )
}

const WidgetsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin: 0 -5px;

  @media (min-width: ${breakpoints.medium}px) {
    flex-wrap: nowrap;
  }
`

interface WidgetColumnProps {
  fluid?: boolean
}

const WidgetColumn = styled.div<WidgetColumnProps>`
  flex: 1;
  ${(props) => (!props.fluid ? 'width: 360px' : '')};
  ${(props) => (!props.fluid ? 'max-width: 360px' : '')};
`
