import {
  faMale,
  faWalking,
  faRunning,
  faBicycle,
  faCar,
  IconDefinition,
  faMapMarkerAlt,
  faHome,
} from '@fortawesome/free-solid-svg-icons'
import moment from 'moment-timezone'

interface Line {
  from_lat: string
  from_long: string
  to_lat: string
  to_long: string
}

function validCoordinate(c: any) {
  const value = parseFloat(c)
  if (isNaN(value)) {
    return false
  }
  if (value.toFixed(2) === '0.00') {
    return false
  }
  return true
}

export function convertToPolylineArraySensor(
  measurements: Api.Measurement[]
): Line[] {
  let holderLat = ''
  let holderLng = ''

  const polylines = measurements
    .filter((loc) => {
      const measurement = JSON.parse(loc.measurement_value)
      const { accuracy, longitude, latitude } = measurement?.coords || {}

      if (!validCoordinate(longitude) || !validCoordinate(latitude)) {
        return false
      }

      if (accuracy === undefined) {
        return true
      }

      return accuracy < 40
    })
    .map(
      (loc): Line => {
        const measurement = JSON.parse(loc.measurement_value)
        const pos = {
          from_lat: measurement.coords?.latitude,
          from_long: measurement.coords?.longitude,
          to_lat: holderLat,
          to_long: holderLng,
        }
        holderLat = measurement.coords?.latitude
        holderLng = measurement.coords?.longitude

        return pos
      }
    )
    .splice(1)

  return polylines
}

export function convertToPolylineArray(
  measurements: Api.TrackerMeasurement[]
): Line[] {
  let holderLat = ''
  let holderLng = ''

  const polylines = measurements
    .filter((loc) => {
      const measurement = JSON.parse(loc.measurement_value)
      const { accuracy, longitude, latitude } = measurement?.coords

      if (!validCoordinate(longitude) || !validCoordinate(latitude)) {
        return false
      }

      if (accuracy === undefined) {
        return true
      }

      return accuracy < 40
    })
    .map(
      (loc): Line => {
        const measurement = JSON.parse(loc.measurement_value)
        const pos = {
          from_lat: measurement.coords.latitude,
          from_long: measurement.coords.longitude,
          to_lat: holderLat,
          to_long: holderLng,
        }
        holderLat = measurement.coords.latitude
        holderLng = measurement.coords.longitude

        return pos
      }
    )
    .splice(1)

  return polylines
}

export function activityIcons(activity: string): IconDefinition {
  switch (activity) {
    case 'house':
      return faHome
    case 'still':
      return faMale
    case 'walking':
      return faWalking
    case 'on_foot':
      return faWalking
    case 'running':
      return faRunning
    case 'on_bicycle':
      return faBicycle
    case 'in_vehicle':
      return faCar
    default:
      return faMapMarkerAlt
  }
}

export function getShortUpdatedAt() {
  return moment().subtract({ minute: 30 }).toISOString()
}

export function getMediumUpdatedAt() {
  return moment().subtract({ hour: 12 }).toISOString()
}

export function getLongUpdatedAt() {
  return moment().subtract({ hour: 24 }).toISOString()
}

export const getRadius = (measurement: any) => {
  if (measurement.coords?.accuracy) {
    return measurement.coords?.accuracy
  }

  return measurement?.dataValid ? 30 : 80
}
