import React from 'react'
import styled from 'styled-components'

const Content = () => {
  const versionDetails =
    `${process.env.REACT_APP_X_VERSION}/${process.env.REACT_APP_X_BUILD_DATE}` +
    `/${process.env.REACT_APP_X_GITHUB_SHA}/${process.env.REACT_APP_X_GITHUB_RUN_NUMBER}`
  return (
    <Container>
      <LinkStyle href="https://nemlia.com" target="_blank">
        www.nemlia.com
      </LinkStyle>
      <LinkStyle href="#" title={versionDetails}>
        &nbsp;
      </LinkStyle>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  height: 80px;
  padding-bottom: 15px;
  position: relative;
  left: 0;
  bottom: 0;
`

const LinkStyle = styled.a`
  font-size: 18px;
  color: black;
  text-decoration: none;
  font-weight: 700;
`

export default Content
