import React, { useContext, useEffect, useRef, useState } from 'react'
import {
  Map as LeafMap,
  TileLayer,
  Marker,
  Polyline,
  Circle,
} from 'react-leaflet'
import Pointer from './Pointer'
import { useSubscription } from '@apollo/client'
import {
  convertToPolylineArray,
  getLongUpdatedAt,
  getRadius,
  getShortUpdatedAt,
  getMediumUpdatedAt,
} from './helpers'
import { MainContext } from '../../MainContext'
import { useTranslation } from 'react-i18next'
import Modal from './Modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import { activityIcons } from './helpers'
import TimeAgo from '../timeago/TimeAgo'
import {
  faClock,
  faBatteryFull,
  faExclamationCircle,
  faLocationArrow,
} from '@fortawesome/free-solid-svg-icons'
import {
  TrackerSubscriptionInput,
  TrackerSubscriptionResponse,
  TRACKER_SUBSCRIPTION,
} from '../../operations/subscriptions/getTrackers'
import { LeafletEvent } from 'leaflet'
import LoadingIndicator from '../loadingIndicator'
import { timeSwitchType, TimeSwitch } from './TimeSwitch'
import { InfoButton } from './InfoButton'
import { faCompress, faExpand } from '@fortawesome/pro-regular-svg-icons'
import { faWifi } from '@fortawesome/pro-solid-svg-icons'
import { Setup } from './SetupGPS'
import { CountdownTimer } from './CountdownTimer'
import moment, { Moment } from 'moment'

const maxUpdatedAt = getLongUpdatedAt()

export default function Map() {
  const { currentCitizen } = useContext(MainContext)
  const [zoom, setZoom] = useState(17)
  const [center, setCenter] = useState<any>(undefined)
  const [lastMeassurement, setLastMeassurement] = useState<any>(undefined)
  const [follow, setFollow] = useState(true)
  const [fullscreen, setFullscreen] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [updatedAt, setUpdatedAt] = useState(getShortUpdatedAt())
  const [timeSwitchValue, setTimeSwitchValue] = useState<timeSwitchType>(
    'short'
  )
  const { t } = useTranslation()
  const [
    filteredTrackerMeasurements,
    setFilteredTrackerMeasurements,
  ] = useState<Api.TrackerMeasurement[]>([])
  const mapRef = useRef<LeafMap>(null)

  const { data, loading, error } = useSubscription<
    TrackerSubscriptionResponse,
    TrackerSubscriptionInput
  >(TRACKER_SUBSCRIPTION, {
    variables: {
      _citizenId: currentCitizen.id,
      _updated_at: maxUpdatedAt,
    },
  })

  useEffect(() => {
    const getUpdateAt = () => {
      if (timeSwitchValue === 'short') {
        return getShortUpdatedAt()
      } else if (timeSwitchValue === 'medium') {
        return getMediumUpdatedAt()
      }
      return getLongUpdatedAt()
    }

    setUpdatedAt(getUpdateAt)
  }, [timeSwitchValue])

  const tracker = data?.ta_tracker[0]
  const trackerMeasurements = tracker?.ta_tracker_measurements

  const lastTrackerMeasurment = tracker?.last_tracker_measurement?.length
    ? JSON.parse(tracker?.last_tracker_measurement[0].measurement_value)
    : undefined

  useEffect(() => {
    if (trackerMeasurements && trackerMeasurements?.length > 0) {
      const filteredTrackerMeasurements =
        trackerMeasurements
          ?.filter((m) => {
            return m.updated_at > updatedAt
          })
          .filter((m) => {
            const { longitude, latitude } = JSON.parse(
              m.measurement_value
            ).coords
            return longitude !== 0 || latitude !== 0
          }) || []

      setFilteredTrackerMeasurements(filteredTrackerMeasurements)
    }
  }, [trackerMeasurements, updatedAt])

  useEffect(() => {
    if (filteredTrackerMeasurements?.length > 0) {
      setLastMeassurement(
        JSON.parse(filteredTrackerMeasurements[0].measurement_value)
      )
    } else {
      setLastMeassurement(undefined)
    }
  }, [filteredTrackerMeasurements])

  useEffect(() => {
    if (follow && lastMeassurement?.coords) {
      setCenter([
        lastMeassurement.coords.latitude,
        lastMeassurement.coords.longitude,
      ])
    }
  }, [follow, lastMeassurement])

  if (loading) {
    return (
      <LoadingContainer>
        <LoadingIndicator color="#fff" />
      </LoadingContainer>
    )
  }

  if (error) {
    console.log(error)
    return <p>{t('locate.setup_error')}</p>
  }

  if (!tracker) {
    return (
      <CenteredContainer>
        <FontAwesomeIcon icon={faExclamationCircle} size={'2x'} />
        <p>{t('locate.no_tracker')}</p>
      </CenteredContainer>
    )
  }

  if (!tracker.is_setup) {
    return (
      <CenteredContainer>
        <Setup tracker={tracker}></Setup>
      </CenteredContainer>
    )
  }

  if (!lastMeassurement && lastTrackerMeasurment) {
    setLastMeassurement(lastTrackerMeasurment)
  }

  if (!lastMeassurement) {
    if (!follow) {
      setFollow(true)
    }

    if (lastTrackerMeasurment) {
      return (
        <>
          <NoData lastTrackerMeasurment={lastTrackerMeasurment} />
          <MeasurementInfo
            lastMeassurement={lastMeassurement}
            lastTrackerMeasurment={lastTrackerMeasurment}
            toggleModal={toggleModal}
          />
          <TimeSwitch
            timeSwitchValue={timeSwitchValue}
            setTimeSwitchValue={setTimeSwitchValue}
          />
        </>
      )
    }

    return (
      <CenteredContainer>
        <p>{t('locate.gps_is_being_set_up')}</p>
        <CountdownTimer duration={60 * 5} initialRemainingTime={60 * 4} />
      </CenteredContainer>
    )
  }

  const polylines = convertToPolylineArray(filteredTrackerMeasurements)

  function toggleModal() {
    setShowModal(!showModal)
  }

  if (!lastMeassurement) return null

  return (
    <Container>
      <MapContainer>
        <LeafMap
          center={center}
          zoom={zoom}
          ondragstart={() => {
            if (follow) {
              setFollow(false)
            }
          }}
          ondragend={() => {
            if (center) {
              setCenter(null)
            }
          }}
          onzoomstart={() => {
            setFollow(false)
          }}
          onzoomend={(event: LeafletEvent) => {
            setZoom(event.target._zoom)
            setCenter(null)
          }}
          style={
            !fullscreen
              ? {
                  position: 'relative',
                  height: 300,
                  minHeight: 300,
                  width: 'auto',
                }
              : {
                  position: 'fixed',
                  width: '100%',
                  height: '100%',
                  left: '0',
                  top: '0',
                  zIndex: 1002,
                }
          }
          ref={mapRef}
        >
          <FollowControlButton
            onClick={() => {
              setFollow(!follow)
            }}
            aria-label="follow"
            background={follow ? '#fff' : '#f4f4f4'}
          >
            <FontAwesomeIcon
              size={'2x'}
              icon={faLocationArrow}
              color={follow ? '#0192da' : '#a2a0a0'}
            ></FontAwesomeIcon>
          </FollowControlButton>
          <FullScreenButton
            background={'#fff'}
            aria-label="full-screen"
            onClick={() => {
              setFullscreen(!fullscreen)
              window.dispatchEvent(new Event('resize'))
            }}
          >
            <FontAwesomeIcon
              size={'2x'}
              icon={!fullscreen ? faExpand : faCompress}
              color={'#222'}
            ></FontAwesomeIcon>
          </FullScreenButton>
          {(!lastMeassurement.dataValid ||
            isDataOld(lastMeassurement.timestamp)) && (
            <NoGPSDataMessage>
              <div>
                <span style={{ marginRight: 7 }}>
                  {t('locate.inaccurate_gps')}
                </span>

                <InfoButton
                  description={t('locate.inaccurate_gps_helper_text')}
                ></InfoButton>
              </div>
            </NoGPSDataMessage>
          )}
          <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {polylines.map((data: any, i: number) => {
            return (
              <Polyline
                key={i}
                positions={[
                  [data.from_lat, data.from_long],
                  [data.to_lat, data.to_long],
                ]}
                color={'#444444'}
              />
            )
          })}
          <Marker
            position={[
              lastMeassurement.coords.latitude,
              lastMeassurement.coords.longitude,
            ]}
            icon={Pointer({ valid: lastMeassurement.dataValid })}
          ></Marker>
          <Circle
            center={[
              lastMeassurement.coords.latitude,
              lastMeassurement.coords.longitude,
            ]}
            radius={getRadius(lastMeassurement)}
          />
        </LeafMap>
      </MapContainer>

      <MeasurementInfo
        toggleModal={toggleModal}
        lastMeassurement={lastMeassurement}
        lastTrackerMeasurment={lastTrackerMeasurment}
      />

      <TimeSwitch
        timeSwitchValue={timeSwitchValue}
        setTimeSwitchValue={setTimeSwitchValue}
      />

      {showModal && (
        <Modal
          measurements={trackerMeasurements ? trackerMeasurements : []}
          toggleModal={toggleModal}
        />
      )}
    </Container>
  )
}

function isDataOld(timestamp: string) {
  let now: Moment = moment()
  let then: Moment = moment.utc(timestamp)

  if (moment.duration(now.diff(then)).asHours() > 2) {
    return true
  }
  return false
}

interface MeasurementInfoProps {
  toggleModal: any
  lastMeassurement: any
  lastTrackerMeasurment: any
}

function MeasurementInfo({
  toggleModal,
  lastMeassurement,
  lastTrackerMeasurment,
}: MeasurementInfoProps) {
  const battery = lastTrackerMeasurment?.battery?.level
  const activityType = lastMeassurement?.activity?.type

  return (
    <MeasurementInfoContainer onClick={toggleModal}>
      {!!lastMeassurement?.timestamp && (
        <InfoItem>
          <IconStyle icon={faClock} />
          <TimeAgo timestamp={lastMeassurement.timestamp} />
        </InfoItem>
      )}

      {battery !== undefined && (
        <InfoItem>
          <IconStyle icon={faBatteryFull} />
          <span>{(battery * 100).toFixed(0)}%</span>
        </InfoItem>
      )}

      {!!activityType && (
        <InfoItem>
          <IconStyle icon={activityIcons(activityType)} />
          <span>{activityType}</span>
        </InfoItem>
      )}

      {!!lastTrackerMeasurment?.timestamp && (
        <InfoItem>
          <IconStyle icon={faWifi} />
          <TimeAgo timestamp={lastTrackerMeasurment.timestamp} />
        </InfoItem>
      )}
    </MeasurementInfoContainer>
  )
}
interface NoDataProps {
  lastTrackerMeasurment: any
}

const NoData = ({ lastTrackerMeasurment }: NoDataProps) => {
  const { t } = useTranslation()
  let withinTime: boolean = lastTrackerMeasurment.updatedAt > getLongUpdatedAt()
  return (
    <CenteredContainer>
      <FontAwesomeIcon icon={faExclamationCircle} size={'2x'} />
      {(withinTime && <p>{t('locate.no_data')}</p>) || (
        <p>{t('locate.no_valid_data')}</p>
      )}
    </CenteredContainer>
  )
}

const CenteredContainer = styled.div`
  color: #fff;
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`

const IconStyle = styled(FontAwesomeIcon)`
  margin: 0 5px 0 0;
  font-size: 25px;
  color: white;
  min-width: 15px;
  max-width: 15px;
  width: 15px;
`

const MeasurementInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 15px 0;

  &:hover {
    cursor: pointer;
  }

  span {
    color: white;
    margin: 0;
    padding: 0;
  }
`

const InfoItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 15px;
`

const FollowControlButton = styled.button<any>`
  position: absolute;
  z-index: 1002;
  right: 0;
  top: 0;
  margin: 10px 10px 0 0;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #bdbcbc;
  border-radius: 4px;
  cursor: pointer;
  background: ${(props) => props.background};

  &:hover {
    background: #efebeb;
  }
`

const FullScreenButton = styled.button<any>`
  position: absolute;
  z-index: 1002;
  left: 0;
  top: 0;
  margin: 80px 0 0 10px;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  cursor: pointer;
  background: ${(props) => props.background};
  background-clip: padding-box;

  &:hover {
    background: #efebeb;
  }
`

const Container = styled.div`
  position: relative;
  margin: 20px 0 0 0;
`

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 30px 0;
`

const NoGPSDataMessage = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 1001;
  padding: 10px;

  & > div {
    border-radius: 8px;
    background: #ca5c5ce0;
    padding: 10px;
    color: #fff;
    z-index: 1001;
  }
`

const MapContainer = styled.div`
  position: relative;
`
