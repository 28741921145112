import {
  faQuestionCircle,
  IconDefinition,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import ReactTooltip from 'react-tooltip'

interface Props {
  description: string
  icon?: IconDefinition
}

export function InfoButton({ description, icon }: Props) {
  return (
    <>
      <span style={{ cursor: 'pointer' }} data-tip={description}>
        <FontAwesomeIcon icon={icon || faQuestionCircle} />
      </span>
      <ReactTooltip effect="solid" />
    </>
  )
}
