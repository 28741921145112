import { gql, useMutation } from '@apollo/client'

interface MutateResponse {
  update_ta_device_by_pk: {
    id: string
    room: string
    location: string
  }
}

interface MutateInput {
  id: string
  room?: string
  location?: string
}

const SET_DEVICE_ROOM_AND_LOCATION = gql`
  mutation SET_DEVICE_ROOM_AND_LOCATION(
    $id: uuid!
    $room: String = null
    $location: String = null
  ) {
    update_ta_device_by_pk(
      pk_columns: { id: $id }
      _set: { room: $room, location: $location }
    ) {
      id
      room
      location
    }
  }
`

export function useSetDeviceRoomAndLocation(input: MutateInput) {
  const [mutate, { data, error, loading }] = useMutation<
    MutateResponse,
    MutateInput
  >(SET_DEVICE_ROOM_AND_LOCATION, {
    variables: input,
  })

  return { mutate, data, error, loading }
}
