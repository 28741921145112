import { gql, useMutation } from '@apollo/client'

interface MutateResponse {
  id: string
  email: string
  consent_accepted: boolean
}

interface MutateInput {
  consent_accepted: boolean
  email: string
}

const User_Consent_Mutation = gql`
  mutation UserConsentMutation($consent_accepted: Boolean!, $email: String!) {
    update_ta_user(
      where: { email: { _eq: $email } }
      _set: { consent_accepted: $consent_accepted }
    ) {
      affected_rows
      returning {
        id
        email
        consent_accepted
      }
    }
  }
`

export function useSetUserConsentMutation(
  onCompleted: (data: MutateResponse) => void
) {
  const [mutate, { error, data }] = useMutation<MutateResponse, MutateInput>(
    User_Consent_Mutation,
    {
      onCompleted: onCompleted,
    }
  )

  return { mutate, data, error }
}
