import { gql } from '@apollo/client'

interface Device {
  model_identifier: string
  online: boolean
}

interface Sensor extends Api.Sensor {
  start?: Api.Measurement[]
  device: Device
}

export interface GetSensorResponse {
  ta_sensor_by_pk: Sensor
}

export interface GetSensorInput {
  id: string
  start_time?: string
  endDate?: string
}

export const GET_SENSOR = gql`
  query GetSensor($id: uuid!) {
    ta_sensor_by_pk(id: $id) {
      id
      sensor_type
      serial_number
      active
      sensor_settings
      ta_sensor_measurements(
        order_by: { data_timestamp: desc_nulls_last }
        limit: 2
      ) {
        id
        measurement_value
        data_timestamp
      }
      sensor_value_type
      ta_device {
        model_identifier
        online
      }
    }
  }
`

export const GET_POWER_SENSOR = gql`
  query GetPowerSensor($id: uuid!, $start_time: timestamptz = "") {
    ta_sensor_by_pk(id: $id) {
      id
      sensor_type
      serial_number
      active
      sensor_settings
      start: ta_sensor_measurements(
        where: {
          archived: { _eq: false }
          measurement_value: { _neq: "Uninitialized", _is_null: false }
          data_timestamp: { _gt: $start_time, _is_null: false }
        }
        order_by: { data_timestamp: asc_nulls_last }
        limit: 1
      ) {
        id
        measurement_value
        data_timestamp
      }
      ta_sensor_measurements(
        where: {
          archived: { _eq: false }
          measurement_value: { _neq: "Uninitialized", _is_null: false }
          data_timestamp: { _is_null: false }
        }
        order_by: { data_timestamp: desc_nulls_last }
        limit: 1
      ) {
        id
        measurement_value
        data_timestamp
      }
      sensor_value_type
      ta_device {
        model_identifier
        online
      }
    }
  }
`

export const GET_OIL_INJECTOR_SENSOR = gql`
  query GetPowerSensor($id: uuid!, $start_time: timestamptz = "") {
    ta_sensor_by_pk(id: $id) {
      id
      sensor_type
      serial_number
      active
      sensor_settings
      ta_sensor_measurements(
        where: {
          archived: { _eq: false }
          measurement_value: { _neq: "Uninitialized", _is_null: false }
          data_timestamp: { _gt: $start_time, _is_null: false }
        }
        order_by: { data_timestamp: asc_nulls_last }
      ) {
        id
        measurement_value
        data_timestamp
      }
      sensor_value_type
      ta_device {
        model_identifier
        online
      }
    }
  }
`
