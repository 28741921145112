import React from 'react'
import { Offline } from 'react-detect-offline'
import { faWifiSlash } from '@fortawesome/pro-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import Notification from '../notification/Notification'

export default function OfflineWarning() {
  const { t } = useTranslation()

  return (
    <Offline polling={{ enabled: false }}>
      <Notification title={t('offline_status')} icon={faWifiSlash} />
    </Offline>
  )
}
