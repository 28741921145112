import React, { useCallback, useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useSubscription } from '@apollo/client'
import Sensors from './Sensors'
import { MainContext } from '../../MainContext'
import LoadingIndicator from '../loadingIndicator'
import { useTranslation } from 'react-i18next'
import {
  PlacesResponse,
  PlacesInput,
  PLACES_QUERY,
} from '../../operations/queries/getPlaces'
import { useSetPlaceRoomAndLocation } from '../../operations/mutations/setPlaceRoomAndLocation'
import { Sensor } from './Sensors'
import { sensorFilterBySensorType } from './helpers'
import MoreInfoButton from '../moreInfoButton'

type placeType = {
  id: string
  title: string
  room: string
  location: string
  ta_sensors: any
}

const Places = () => {
  const { t } = useTranslation()
  const { currentCitizen } = useContext(MainContext)
  const { data, loading, error } = useSubscription<PlacesResponse, PlacesInput>(
    PLACES_QUERY,
    {
      variables: {
        _citizenId: currentCitizen.id,
      },
    }
  )

  if (loading) {
    return (
      <LoadingContainer>
        <LoadingIndicator />
      </LoadingContainer>
    )
  }

  if (error) {
    console.error(`Error in PLACES_QUERY: ${error}`, {
      data: data,
      query: PLACES_QUERY,
      citizen: currentCitizen,
    })
    return <p>{t('general.error')}</p>
  }

  if (!data) return null

  return (
    <Container>
      {data.ta_place.map(
        ({ id, title, room, location, ta_sensors }: placeType) => {
          const sensors = ta_sensors
          const [
            infoBarSensors,
            mainSensors,
          ] = sensorFilterBySensorType(sensors, [
            'network-strength',
            'networklinkstrength',
            'battery',
            'voltage',
          ])

          const offline = !!sensors.find(
            (s: any) => !!s.ta_device && !s.ta_device?.online
          )

          return (
            <PlaceContainer
              key={id}
              placeBackgroundColor={'rgb(80 175 159 / 28%)'}
              offline={offline}
            >
              <PlaceHeader>
                <div>
                  <HeaderStyle>
                    <HeadlineStyle fontSize={21} color="white">
                      {t(`measure.places.title.${title}`, {
                        defaultValue: title,
                      })}
                    </HeadlineStyle>
                  </HeaderStyle>

                  <PlaceHeaderInfoBar
                    sensors={infoBarSensors}
                    offline={offline}
                  />
                </div>

                <HeaderSubStyle>
                  <EditRoomAndLocation
                    id={id}
                    room={room}
                    location={location}
                  />
                </HeaderSubStyle>
              </PlaceHeader>

              <Sensors sensors={mainSensors} offline={offline} />
              {(title === 'Luftgóðskumátari' ||
                title === 'Air Quality Sensor') && <MoreInfoButton />}
            </PlaceContainer>
          )
        }
      )}
    </Container>
  )
}

const Content = () => {
  return <Places />
}

const EditRoomAndLocation = ({ id, room, location }: any) => {
  const { t } = useTranslation()
  const [roomValue, setRoomValue] = useState(room)
  const [locationValue, setLocationValue] = useState(location)
  const [editMode, setEditMode] = useState(false)
  const { mutate: setPlaceTitle, loading } = useSetPlaceRoomAndLocation({
    id,
    room: roomValue,
    location: locationValue,
  })

  const handleReset = useCallback(() => {
    setRoomValue(room)
    setLocationValue(location)
    setEditMode(false)
  }, [room, location])

  const escFunction = useCallback(
    (event: any) => {
      if (event.target.name === id && event.keyCode === 27) {
        handleReset()
      }
    },
    [handleReset, id]
  )

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false)

    return () => {
      document.removeEventListener('keydown', escFunction, false)
    }
  }, [escFunction])

  function handleSubmit(e: any) {
    if (roomValue !== room || locationValue !== location) {
      setPlaceTitle()
    }

    setEditMode(false)
    e.preventDefault()
  }

  function renderRoomAndLocationText() {
    return (
      <>
        {roomValue || t('measure.places.room_placeholder')}{' '}
        {locationValue ? ` / ${locationValue}` : ''}
      </>
    )
  }
  if (loading) {
    return (
      <div>
        <HeadlineStyleWithLoading fontSize={14}>
          {renderRoomAndLocationText()}
        </HeadlineStyleWithLoading>
        <LoadingIndicator color="#fff" size={14} />
      </div>
    )
  }

  if (editMode) {
    return (
      <PlaceRoomAndLocationForm onSubmit={handleSubmit}>
        <input
          autoFocus
          name={`placeRoom-${id}`}
          type="text"
          value={roomValue || ''}
          placeholder={t('measure.places.room_placeholder')}
          onChange={(e) => setRoomValue(e.target.value)}
        />
        <input
          name={`placeLocation-${id}`}
          type="text"
          value={locationValue || ''}
          placeholder={t('measure.places.location_placeholder')}
          onChange={(e) => setLocationValue(e.target.value)}
        />
        <button type="submit">{t(`general.save`)}</button>
        <button type="reset" onClick={handleReset}>
          {t(`general.cancel`)}
        </button>
      </PlaceRoomAndLocationForm>
    )
  }

  return (
    <div onClick={() => setEditMode(true)}>
      <HeadlineStyle fontSize={14} subtitle>
        {renderRoomAndLocationText()}
      </HeadlineStyle>
    </div>
  )
}

const PlaceTextForm = styled.form`
  display: flex;
  z-index: 1;

  & > input {
    width: 100%;
    border: none;
    outline: none;
    border-radius: 8px;
    margin-right: 7px;
    padding: 4px 10px;
  }
`

const PlaceRoomAndLocationForm = styled(PlaceTextForm)`
  padding: 1rem 0 0 0;

  & > input {
    font-size: 0.6rem;
    text-transform: uppercase;
    padding: 0.5rem;
    border-radius: 0.2rem;
  }
  & > button {
    color: white;
    text-transform: uppercase;
    font-size: 0.6rem;
    font-weight: 600;
    background: #00000020;
    border: 1px solid #ffffff70;
    border-radius: 10rem;
    cursor: pointer;
    padding: 0.2rem 0.5rem;
    &:first-of-type {
      margin-right: 0.2rem;
    }
    &:hover {
      background: #000;
      border: 1px solid white;
    }
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin: 20px 0 0 0;
  flex-wrap: wrap;
  justify-content: space-between;
`

type PlaceContainerProps = {
  placeBackgroundColor: string
  offline: boolean
}

const PlaceContainer = styled.div<PlaceContainerProps>`
  display: flex;
  flex-direction: column;
  min-height: 200px;
  background: ${(props) => props.placeBackgroundColor};
  border-radius: 1rem;
  margin: 0 0 2rem;
  width: 285px;
  box-shadow: 0 5px 10px -5px #00000052;
  background: linear-gradient(45deg, #265a56, #29796b);
  border: 2px solid ${(props) => (props.offline ? 'red' : '#ffffff26')};
  position: relative;
`

const HeaderStyle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #ffffffc7;

  /* Must be min-width: 0, for ellipis to work */
  min-width: 0;
`

const HeaderSubStyle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #ffffffc7;
`

interface HeadlineStyleProps {
  fontSize: number
  subtitle?: boolean
  color?: string
}

const HeadlineStyle = styled.div<HeadlineStyleProps>`
  overflow: hidden;
  white-space: break-spaces;
  text-overflow: ellipsis;
  font-weight: 700;
  font-size: ${(props) => props.fontSize}px;
  ${(props) => props.color && `color:${props.color};`}
  ${(props) =>
    props.subtitle &&
    `font-weight: 800;
    letter-spacing: 1px;
    text-transform: uppercase;`};

  /* Used for displaying ellipis on long names */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const HeadlineStyleWithLoading = styled(HeadlineStyle)`
  display: inline-block;
  margin-right: 15px;
`

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 30px 0;
`

const PlaceHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background: #00000010;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  margin-bottom: 2rem;

  & > div:first-of-type {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    & > svg {
      margin-right: 0.5rem;
      &:last-of-type {
        margin: 0;
      }
    }
  }
`

const PlaceHeaderInfoBar = styled(({ className, sensors, offline }) => {
  const { t } = useTranslation()
  const [sensorsLoading, setSensorsLoading] = useState(0)
  const sensorsComponents = sensors
    .map((x: any) => (
      <Sensor
        setSensorsLoading={setSensorsLoading}
        key={x.id + 'ded'}
        sensorId={x.id}
        updated_at={x.updated_at}
        sensor_type={x.sensor_type}
        offline={offline}
      />
    ))
    .reverse()

  return (
    <div className={className}>
      {sensorsComponents}
      {sensorsLoading < sensorsComponents.length && (
        <span>{t('general.loading')}</span>
      )}
    </div>
  )
})`
  display: flex;
  flex-direction: row;
  min-height: 1rem;
  & > svg {
    margin-right: 0.5rem;
    &:last-of-type {
      margin: 0;
    }
  }
  & > span {
    color: #ffffff40;
  }
`

export default Content
