import { gql, useMutation } from '@apollo/client'

interface MutateInput {
  id: string
  citizen_name: string
}

interface MutateResponse {
  update_ta_citizen_by_pk: {
    citizen_name: string
  }
}

const SET_CITIZEN_NAME = gql`
  mutation SET_CITIZEN_NAME($id: uuid!, $citizen_name: String) {
    update_ta_citizen_by_pk(
      pk_columns: { id: $id }
      _set: { citizen_name: $citizen_name }
    ) {
      id
      citizen_name
    }
  }
`

export const useSetCitizenName = (input: MutateInput) => {
  const [mutate, { data, error, loading }] = useMutation<
    MutateResponse,
    MutateInput
  >(SET_CITIZEN_NAME, {
    variables: input,
  })
  return { mutate, data, error, loading }
}
