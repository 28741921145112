import React from 'react'
import moment from 'moment-timezone'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { activityIcons } from './helpers'
import Modal from '../modal/Modal'
import { useTranslation } from 'react-i18next'

interface Measurement {
  id: string
  measurement_value: string
  updated_at: string
  __typename: string
}

interface ModalProps {
  measurements: Measurement[]
  toggleModal: any
}

const TrackerMeasurmentsModal = ({ measurements, toggleModal }: ModalProps) => {
  const { t } = useTranslation()

  if (measurements.length <= 0) {
    return null
  }

  return (
    <Modal title={t(`locate.activity`)} toggleModal={toggleModal}>
      <Content>
        <LogTable>
          <LogRow key="headers">
            <LogColumn>
              <BoldLogStyle>{t(`locate.location_placeholder`)}</BoldLogStyle>
            </LogColumn>
            <LogColumn>
              <BoldLogStyle>{t(`locate.speed`)}</BoldLogStyle>
            </LogColumn>
            <LogColumn>
              <BoldLogStyle>{t(`locate.battery`)}</BoldLogStyle>
            </LogColumn>
            <LogColumn>
              <BoldLogStyle>{t(`locate.timestamp`)}</BoldLogStyle>
            </LogColumn>
          </LogRow>
          {measurements.map((measurement) => {
            const value = JSON.parse(measurement.measurement_value)
            let activityType = value.activity?.type

            if (!value.dataValid) {
              activityType = 'house'
            }

            return (
              <LogRow key={measurement.id}>
                <LogColumn>
                  <NormalLogStyle>
                    <IconStyle icon={activityIcons(activityType)} />
                  </NormalLogStyle>
                </LogColumn>

                <LogColumn>
                  <NormalLogStyle>{`${value.coords.speed}`}</NormalLogStyle>
                </LogColumn>
                <LogColumn>
                  <NormalLogStyle>
                    {`${value.battery.level * 100}%`}
                  </NormalLogStyle>
                </LogColumn>
                <LogColumn>
                  <NormalLogStyle>
                    {moment
                      .utc(measurement.updated_at)
                      .local()
                      .format('HH:mm:ss - L')}
                  </NormalLogStyle>
                </LogColumn>
              </LogRow>
            )
          })}
        </LogTable>
      </Content>
    </Modal>
  )
}

export default TrackerMeasurmentsModal

const IconStyle = styled(FontAwesomeIcon)`
  font-size: 15px;
  color: ${(props) => (props.color === 'light' ? 'white' : '#909090')};
  min-width: 15px;
  max-width: 15px;
  width: 15px;
`

const LogTable = styled.ul`
  display: table;
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
  overflow-y: auto;
`

const LogRow = styled.li`
  display: table-row;
  align-items: center;
  justify-content: space-around;
`

const LogColumn = styled.div`
  display: table-cell;
  text-align: center;
`

const NormalLogStyle = styled.p`
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  color: #909090;
  margin: 0;
`

const BoldLogStyle = styled(NormalLogStyle)`
  margin-bottom: 1rem;
`

const Content = styled.div`
  padding: 1rem;
  overflow: auto;
`
