import { Icon } from 'leaflet'
import markerRed from '../../images/marker-icon.svg'
import markerGray from '../../images/marker-icon-gray.svg'

interface Props {
  valid: boolean
}

const pointerIcon = ({ valid }: Props) => {
  const img = valid ? markerRed : markerGray

  return new Icon({
    iconUrl: img,
    iconRetinaUrl: img,
    iconAnchor: [10, 10],
    popupAnchor: [10, -44],
    iconSize: [20, 20],
    shadowSize: [68, 95],
    shadowAnchor: [20, 92],
  })
}

export default pointerIcon
