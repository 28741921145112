import React, { useCallback, useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import Places from './Places'
import { breakpoints } from '../../utils/breakpoints'
import Devices from './Devices'
import { MainContext } from '../../MainContext'
import { useSetCitizenName } from '../../operations/mutations/setCitizenName'
import LoadingIndicator from '../loadingIndicator'
import CitizenLog from './CitizenLog'

const VIEWS = {
  DEVICE_VIEW: 'device_view',
}

const Content = () => {
  const { currentCitizen } = useContext(MainContext)

  return (
    <Container id="measure">
      <HeaderStyle>
        <EditCitizenName
          id={currentCitizen.id}
          citizen_name={currentCitizen.citizen_name}
        ></EditCitizenName>
        <CitizenLog />
      </HeaderStyle>
      <RenderView />
    </Container>
  )
}

const EditCitizenName = ({ id, citizen_name }: any) => {
  const { t } = useTranslation()
  const [newCitizenName, setNewCitizenName] = useState(citizen_name)
  const [editMode, setEditMode] = useState(false)
  const { mutate: setCitizenName, loading } = useSetCitizenName({
    id,
    citizen_name: newCitizenName,
  })

  const handleReset = useCallback(() => {
    setNewCitizenName(citizen_name)
    setEditMode(false)
  }, [citizen_name])

  const escFunction = useCallback(
    (event: any) => {
      if (event.target.name === id && event.keyCode === 27) {
        handleReset()
      }
    },
    [handleReset, id]
  )

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false)

    return () => {
      document.removeEventListener('keydown', escFunction, false)
    }
  }, [escFunction])

  function handleSubmit(e: any) {
    if (newCitizenName !== citizen_name) {
      setCitizenName()
    }

    setEditMode(false)
    e.preventDefault()
  }

  function renderCitizenName() {
    return <>{citizen_name ? `${citizen_name}` : ''}</>
  }
  if (loading) {
    return (
      <div>
        <HeadlineStyleWithLoading>
          {renderCitizenName()}
        </HeadlineStyleWithLoading>
        <LoadingIndicator color="#fff" size={14} />
      </div>
    )
  }

  if (editMode) {
    return (
      <CitizenNameForm onSubmit={handleSubmit}>
        <input
          autoFocus
          name={`citizen-${id}`}
          type="text"
          value={newCitizenName}
          onChange={(e) => setNewCitizenName(e.target.value)}
        />
        <button type="submit">{t(`general.save`)}</button>
        <button type="reset" onClick={handleReset}>
          {t(`general.cancel`)}
        </button>
      </CitizenNameForm>
    )
  }

  return (
    <div onClick={() => setEditMode(true)}>
      <HeadlineStyle>{renderCitizenName()}</HeadlineStyle>
    </div>
  )
}

const RenderView = () => {
  const { currentCitizen } = useContext(MainContext)

  if (currentCitizen?.measure_view === VIEWS.DEVICE_VIEW) {
    return <Devices />
  }

  return <Places />
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 200px;
  background-color: #27514a;
  border-radius: 10px;
  margin: 0 5px 15px 5px;
  padding: 1rem;

  @media (min-width: ${breakpoints.small}px) {
    padding: 2rem;
  }
`

const CitizenNameTextForm = styled.form`
  display: flex;
  z-index: 1;

  & > input {
    width: 100%;
    border: none;
    outline: none;
    border-radius: 8px;
    margin-right: 7px;
    padding: 4px 10px;
  }
`

const CitizenNameForm = styled(CitizenNameTextForm)`
  padding: 1rem 0 0 0;

  & > input {
    font-size: 0.9rem;
    text-transform: uppercase;
    padding: 0.5rem;
    border-radius: 0.2rem;
  }
  & > button {
    color: white;
    text-transform: uppercase;
    font-size: 0.7rem;
    font-weight: 600;
    background: #00000020;
    border: 1px solid #ffffff70;
    border-radius: 10rem;
    cursor: pointer;
    padding: 0.2rem 0.5rem;
    width: 100px;
    &:first-of-type {
      margin-right: 0.2rem;
    }
    &:hover {
      background: #000;
      border: 1px solid white;
    }
  }
`

const HeaderStyle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #ffffffe6;
  flex-wrap: wrap;
`

const HeadlineStyle = styled.div`
  font-size: 36px;
  font-weight: 700;
  cursor: pointer;
`

const HeadlineStyleWithLoading = styled(HeadlineStyle)`
  display: inline-block;
  margin-right: 15px;
`

export default Content
