import React, { useContext } from 'react'
import { MainContext } from '../../MainContext'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import Notification from '../notification/Notification'
import { useTranslation } from 'react-i18next/'

export const GatewayOffline = () => {
  const { t } = useTranslation()
  const { offlineGateways } = useContext(MainContext)

  if (!offlineGateways) return null

  return (
    <Notification
      title={t('general.gateway_offline', { gateway_eui: offlineGateways })}
      icon={faExclamationCircle}
    />
  )
}
