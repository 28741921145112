import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { InfoButton } from './InfoButton'

export type timeSwitchType = 'short' | 'medium' | 'long'

interface TimeSwitchProps {
  timeSwitchValue: timeSwitchType
  setTimeSwitchValue: React.Dispatch<React.SetStateAction<timeSwitchType>>
}

export function TimeSwitch({
  timeSwitchValue,
  setTimeSwitchValue,
}: TimeSwitchProps) {
  const { t } = useTranslation()

  return (
    <TimeSwitchContainer>
      <Title>
        <span style={{ marginRight: 7 }}>{t('locate.tracks')}:</span>
        <InfoButton description={t('locate.tracks_helpertext')}></InfoButton>
      </Title>
      <TimeSwitchButtonWrapper>
        <TimeSwitchButton
          active={timeSwitchValue === 'short'}
          onClick={() => setTimeSwitchValue('short')}
        >
          {t('locate.short_timespan')}
        </TimeSwitchButton>
        <TimeSwitchButton
          active={timeSwitchValue === 'medium'}
          onClick={() => setTimeSwitchValue('medium')}
        >
          {t('locate.medium_timespan')}
        </TimeSwitchButton>
        <TimeSwitchButton
          active={timeSwitchValue === 'long'}
          onClick={() => setTimeSwitchValue('long')}
        >
          {t('locate.long_timespan')}
        </TimeSwitchButton>
      </TimeSwitchButtonWrapper>
    </TimeSwitchContainer>
  )
}

const Title = styled.div`
  color: #fff;
  margin-bottom: 10px;
  position: relative;
`

const TimeSwitchContainer = styled.div`
  margin: 15px 0;
  padding: 15px 0;
`

const TimeSwitchButtonWrapper = styled.div`
  border-radius: 8px;
  display: flex;
  overflow: hidden;
  background: #5a0075;
  padding: 4px;
`

interface TimeSwitchButtonProps {
  active?: boolean
}

const TimeSwitchButton = styled.button<TimeSwitchButtonProps>`
  background: ${(props) => (props.active ? '#c053d6' : 'transparent')};
  padding: 10px;
  color: #fff;
  font-size: 18px;
  border: none;
  border-radius: 8px;
  outline: none;
  cursor: pointer;
  width: 100%;
  font-weight: bold;
`
