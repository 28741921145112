import React from 'react'
import styled from 'styled-components'

// @ts-ignore
import { Spinner } from 'react-activity'
import 'react-activity/dist/react-activity.css'

interface LoadingIndicatorProps {
  fullscreen?: boolean
  color?: string
  size?: number
}

export default function LoadingIndicator({
  fullscreen,
  color = '#50af9f',
  size = 30,
}: LoadingIndicatorProps) {
  if (fullscreen) {
    return (
      <LoadingContainer>
        <Spinner size={size} color={color} />
      </LoadingContainer>
    )
  }

  return <Spinner size={size} color={color} />
}

const LoadingContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
`
