import { useQuery } from '@apollo/client'
import { useAuth0 } from '@auth0/auth0-react'
import React, { FormEvent, useState } from 'react'
import { useTranslation } from 'react-i18next/'
import styled from 'styled-components'
import { GET_USER } from '../../operations/queries/getUser'
import { apiUrl } from '../../utils/helpers'
import LoadingIndicator from '../loadingIndicator'
import LanguageSelector from './LanguageSelector'
interface ActivationFormProps {
  refetchCitizen: any
  refetchUser: any
}

export function ActivationForm({
  refetchCitizen,
  refetchUser,
}: ActivationFormProps) {
  const { t } = useTranslation()
  const { user } = useAuth0()
  const [activationCode, setActivationCode] = useState('')
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const userQuery = useQuery(GET_USER, {
    variables: { email: user?.email },
  })

  function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault()

    if (loading) return

    setLoading(true)
    setErrorMessage('')

    const userName = user?.given_name || user?.name
    const userId = userQuery.data.ta_user[0].id

    let myHeaders = new Headers()
    myHeaders.append('accept', 'application/json')
    myHeaders.append('Authorization', process.env.REACT_APP_AUTH_BASIC || '')
    myHeaders.append('Content-Type', 'application/json')

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({ userName, userId, activationCode }),
    }

    fetch(`${apiUrl()}/rest/v1/styra/activation-code`, requestOptions)
      .then(async (response) => {
        if (response.status !== 200) {
          setLoading(false)
          setErrorMessage(t('activation.incorrect_activation_code'))
        } else if (!response.ok) {
          setLoading(false)
          setErrorMessage(response.statusText)
        }

        await refetchUser()
        refetchCitizen()
      })
      .catch((error) => {
        setLoading(false)
        setErrorMessage(error.message)
      })
  }

  return (
    <Container>
      <LanguageSelector />
      <h2>{t('activation.title')}</h2>
      <Description>{t('activation.description')}</Description>

      <form onSubmit={(event) => handleSubmit(event)}>
        <Input
          disabled={loading}
          type="text"
          placeholder={t('activation.placeholder')}
          value={activationCode}
          onChange={(event) => setActivationCode(event.target.value)}
        />
        <ErrorMessage>{errorMessage}</ErrorMessage>
        <ActivateButton loading={loading} />
      </form>
    </Container>
  )
}

interface ActivateButtonProps {
  loading: boolean
}

function ActivateButton({ loading }: ActivateButtonProps) {
  const { t } = useTranslation()

  if (loading) {
    return (
      <LoadingContainer>
        <LoadingIndicator />
      </LoadingContainer>
    )
  }

  return <Button type="submit">{t('activation.submit')}</Button>
}

const LoadingContainer = styled.div`
  text-align: center;
`

const Container = styled.div`
  background: linear-gradient(45deg, rgb(192, 197, 197), rgb(210, 210, 210));
  padding: 20px;
  border-radius: 8px;
  box-shadow: rgb(0 0 0) 0px 4px 5px -5px;
`

const Input = styled.input`
  padding: 15px;
  width: 100%;
  margin-bottom: 30px;
  border: 1px solid #ccc;
  border-radius: 2px;
`

const Button = styled.button`
  background: rgb(80, 175, 159);
  padding: 10px;
  color: rgb(255, 255, 255);
  font-size: 24px;
  border: none;
  border-radius: 8px;
  outline: none;
  cursor: pointer;
  width: 100%;
  font-weight: bold;
`

const Description = styled.p`
  max-width: 320px;
  color: #000;
  margin-bottom: 40px;
`

const ErrorMessage = styled.p`
  color: red;
  margin: -10px 0 20px;
`
