import { gql } from '@apollo/client'

export interface DevicesInput {
  _citizenId: string
}

interface Sensor {
  id: string
  sensor_type: string
  updated_at: string
}

interface Gateway {
  id: string
  ta_citizen_gateways: {
    citizen_id: string
    ta_citizen: {
      citizen_name: string
    }
  }
}

export interface Device {
  id: string
  name: string
  ta_gateway: Gateway
  ta_sensors: Sensor[]
  view_template: any
  model_identifier: string
  room: string
  location: string
  online: boolean
  eui: string
}

export interface DevicesResponse {
  ta_device: Device[]
}

export const DEVICES_SUBSCRIPTION = gql`
  subscription DevicesQuery($_citizenId: uuid!) {
    ta_device(
      where: { ta_citizen_devices: { citizen_id: { _eq: $_citizenId } } }
      order_by: { id: asc }
    ) {
      id
      name
      view_template
      model_identifier
      room
      location
      online
      eui
      ta_gateway {
        id
        ta_citizen_gateways {
          citizen_id
          ta_citizen {
            citizen_name
          }
        }
      }
      ta_sensors(where: { archived: { _eq: false } }, order_by: { id: asc }) {
        id
        sensor_type
        updated_at
      }
    }
  }
`
