import React, { Suspense, useState, lazy } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const CitizenLogModal = lazy(() => import('./modals/CitizenLogModal'))

const CitizenLog = () => {
  const [modalOpen, setModalOpen] = useState(false)
  const { t } = useTranslation()

  return (
    <>
      {!modalOpen && (
        <Container>
          <Button onClick={() => setModalOpen(!modalOpen)}>
            <ButtonText>{t(`general.citizen_log`)}</ButtonText>
          </Button>
        </Container>
      )}
      {modalOpen && (
        <Suspense fallback={<div>...loading</div>}>
          <CitizenLogModal setModalOpen={setModalOpen} modalOpen={modalOpen} />
        </Suspense>
      )}
    </>
  )
}

export default CitizenLog

const Container = styled.div`
  font-size: 18px;
  margin: 0 0 20px 0;
`

const Button = styled.button`
  margin: 10px 0;
  padding: 10px;
  border-radius: 10px;
  background-color: #50af9f;
  border: 0;
  padding: 10px 30px;
  outline: none;
  box-shadow: 0 5px 10px -5px #00000052;
  background: linear-gradient(45deg, #265a56, #29796b);
  border: 2px solid #ffffff73;
  border-radius: 1rem;
  cursor: pointer;

  @media {
    padding: 5px 20px;
    margin: 5px;
    background: #f7f8f8b0;
  }
`

const ButtonText = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: #000;
  text-align: left;
`
