import { gql } from '@apollo/client'

export interface Tracker {
  id: string
  ta_tracker_measurements: Api.TrackerMeasurement[]
  last_tracker_measurement: Api.TrackerMeasurement[]
  is_setup: boolean
}

export interface TrackerSubscriptionResponse {
  ta_tracker: Tracker[]
}

export interface TrackerSubscriptionInput {
  _citizenId: string
  _updated_at: string
}

export const TRACKER_SUBSCRIPTION = gql`
  subscription TRACKER_SUBSCRIPTION(
    $_citizenId: uuid!
    $_updated_at: timestamp!
  ) {
    ta_tracker(
      where: {
        _and: { archived: { _eq: false }, citizen_id: { _eq: $_citizenId } }
      }
    ) {
      id
      tracker_type
      ta_tracker_measurements(
        where: { updated_at: { _gt: $_updated_at } }
        order_by: { updated_at: desc }
      ) {
        id
        measurement_value
        updated_at
      }
      last_tracker_measurement: ta_tracker_measurements(
        order_by: { updated_at: desc }
        limit: 1
      ) {
        id
        measurement_value
        updated_at
      }
      is_setup
    }
  }
`
