import React from 'react'
import styled from 'styled-components'

interface Props {
  secondary?: boolean
  children: any
}

export default function NormalText({ children, secondary }: Props) {
  if (secondary) {
    return <SecondaryTextStyle>{children}</SecondaryTextStyle>
  }

  return <NormalTextStyle>{children}</NormalTextStyle>
}

export const NormalTextStyle = styled.span`
  font-size: 1.3rem;
  font-weight: 700;
  color: white;
`

const SecondaryTextStyle = styled(NormalTextStyle)`
  font-size: 1rem;
`
