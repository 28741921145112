import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import MoreInfoButton from '../moreInfoButton'
import { Sensor } from './Sensors'
import Sensors from './DeviceSensors'
import { sensorFilterBySensorType } from './helpers'
import { Device as DeviceType } from '../../operations/subscriptions/getDevices'
import LoadingIndicator from '../loadingIndicator'
import { useSetDeviceRoomAndLocation } from '../../operations/mutations/setDeviceRoomAndLocation'

interface Props {
  device: DeviceType
}

export default function Device({ device }: Props) {
  const { t } = useTranslation()

  const { id, name, ta_sensors, view_template, room, location } = device

  const sensors = ta_sensors.filter((s) => s.sensor_type)

  const [infoBarSensors, mainSensors] = sensorFilterBySensorType(sensors, [
    'network-strength',
    'networklinkstrength',
    'battery',
    'batterypercentage',
    'voltage',
    'networklinkaddress',
  ])

  return (
    <DeviceContainer
      key={id}
      deviceBackgroundColor={'rgb(80 175 159 / 28%)'}
      offline={!device.online}
    >
      <DeviceHeader>
        <div>
          <HeaderStyle>
            <HeadlineStyle fontSize={21} color="white">
              {t(`measure.places.title.${name}`, {
                defaultValue: name,
              })}
            </HeadlineStyle>
          </HeaderStyle>
          <DeviceHeaderInfoBar sensors={infoBarSensors} device={device} />
        </div>

        <HeaderStyle>
          <EditRoomAndLocation id={id} room={room} location={location} />
        </HeaderStyle>
      </DeviceHeader>

      <Sensors
        view_template={view_template}
        sensors={mainSensors.map((s: any) => ({
          id: s.id,
          sensor_type: s.sensor_type,
          updated_at: s.updated_at,
        }))}
        offline={!device.online}
      />
      {name === 'Air Quality Sensor' && <MoreInfoButton />}
      <DeviceEui title={device.eui}>{device.eui}</DeviceEui>
    </DeviceContainer>
  )
}

const EditRoomAndLocation = ({ id, room, location }: any) => {
  const { t } = useTranslation()
  const [roomValue, setRoomValue] = useState(room)
  const [locationValue, setLocationValue] = useState(location)
  const [editMode, setEditMode] = useState(false)
  const {
    mutate: setDeviceRoomLocation,
    loading,
  } = useSetDeviceRoomAndLocation({
    id,
    room: roomValue,
    location: locationValue,
  })

  const handleReset = useCallback(() => {
    setRoomValue(room)
    setLocationValue(location)
    setEditMode(false)
  }, [room, location])

  const escFunction = useCallback(
    (event: any) => {
      if (event.target.name === id && event.keyCode === 27) {
        handleReset()
      }
    },
    [handleReset, id]
  )

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false)

    return () => {
      document.removeEventListener('keydown', escFunction, false)
    }
  }, [escFunction])

  function handleSubmit(e: any) {
    if (roomValue !== room || locationValue !== location) {
      setDeviceRoomLocation()
    }

    setEditMode(false)
    e.preventDefault()
  }

  function renderRoomAndLocationText() {
    return (
      <>
        {roomValue || t('measure.places.room_placeholder')}{' '}
        {locationValue ? ` / ${locationValue}` : ''}
      </>
    )
  }
  if (loading) {
    return (
      <div>
        <HeadlineStyleWithLoading fontSize={14}>
          {renderRoomAndLocationText()}
        </HeadlineStyleWithLoading>
        <LoadingIndicator color="#fff" size={14} />
      </div>
    )
  }

  if (editMode) {
    return (
      <DeviceRoomAndLocationForm onSubmit={handleSubmit}>
        <input
          autoFocus
          name={`deviceRoom-${id}`}
          type="text"
          value={roomValue || ''}
          placeholder={t('measure.places.room_placeholder')}
          onChange={(e) => setRoomValue(e.target.value)}
        />
        <input
          name={`deviceLocation-${id}`}
          type="text"
          value={locationValue || ''}
          placeholder={t('measure.places.location_placeholder')}
          onChange={(e) => setLocationValue(e.target.value)}
        />
        <button type="submit">{t(`general.save`)}</button>
        <button type="reset" onClick={handleReset}>
          {t(`general.cancel`)}
        </button>
      </DeviceRoomAndLocationForm>
    )
  }

  return (
    <div onClick={() => setEditMode(true)}>
      <HeadlineStyle fontSize={14} subtitle>
        {renderRoomAndLocationText()}
      </HeadlineStyle>
    </div>
  )
}

const DeviceTextForm = styled.form`
  display: flex;
  z-index: 1;

  & > input {
    width: 100%;
    border: none;
    outline: none;
    border-radius: 8px;
    margin-right: 7px;
    padding: 4px 10px;
  }
`

const DeviceRoomAndLocationForm = styled(DeviceTextForm)`
  padding: 1rem 0 0 0;

  & > input {
    font-size: 0.6rem;
    text-transform: uppercase;
    padding: 0.5rem;
    border-radius: 0.2rem;
  }
  & > button {
    color: white;
    text-transform: uppercase;
    font-size: 0.6rem;
    font-weight: 600;
    background: #00000020;
    border: 1px solid #ffffff70;
    border-radius: 10rem;
    cursor: pointer;
    padding: 0.2rem 0.5rem;
    &:first-of-type {
      margin-right: 0.2rem;
    }
    &:hover {
      background: #000;
      border: 1px solid white;
    }
  }
`

type DeviceContainerProps = {
  deviceBackgroundColor: string
  offline: boolean
}

const DeviceContainer = styled.div<DeviceContainerProps>`
  display: flex;
  flex-direction: column;
  min-height: 200px;
  background: ${(props) => props.deviceBackgroundColor};
  border-radius: 1rem;
  margin: 0 0 2rem;
  width: 285px;
  box-shadow: 0 5px 10px -5px #00000052;
  background: linear-gradient(45deg, #265a56, #29796b);
  border: 2px solid ${(props) => (props.offline ? 'red' : '#ffffff26')};
`

const HeaderStyle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #ffffffc7;

  /* Must be min-width: 0, for ellipis to work */
  min-width: 0;
`

const DeviceEui = styled.div`
  display: inline-block;
  font-style: italic;
  margin-top: auto;
  padding: 5px 1rem;
  color: #fff;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;

  /* "direction: rtl" makes the ellipsis appear on the left side of the EUI */
  direction: rtl;
`

interface HeadlineStyleProps {
  fontSize: number
  subtitle?: boolean
  color?: string
}

const HeadlineStyle = styled.div<HeadlineStyleProps>`
  font-size: ${(props) => props.fontSize}px;
  font-weight: 700;
  word-break: break-all;
  ${(props) => props.color && `color:${props.color};`}
  ${(props) =>
    props.subtitle &&
    `font-weight: 800;
    letter-spacing: 1px;
    text-transform: uppercase;`};

  /* Used for displaying ellipis on long names */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const HeadlineStyleWithLoading = styled(HeadlineStyle)`
  display: inline-block;
  margin-right: 15px;
`

const DeviceHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background: #00000010;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  margin-bottom: 1rem;

  & > div:first-of-type {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`

const DeviceHeaderInfoBar = styled(({ className, sensors, device }) => {
  const { t } = useTranslation()
  const [sensorsLoading, setSensorsLoading] = useState(0)
  const sensorsComponents = sensors
    .map((x: any) => (
      <Sensor
        setSensorsLoading={setSensorsLoading}
        key={x.id + 'ded'}
        sensorId={x.id}
        updated_at={x.updated_at}
        sensor_type={x.sensor_type}
        offline={!device.online}
      />
    ))
    .reverse()

  return (
    <div className={className}>
      {sensorsComponents}
      {sensorsLoading < sensorsComponents.length && (
        <span>{t('general.loading')}</span>
      )}
    </div>
  )
})`
  display: flex;
  flex-direction: row;
  min-height: 1rem;
  & > svg {
    margin-right: 0.5rem;
    &:last-of-type {
      margin: 0;
    }
  }
  & > span {
    color: #ffffff40;
  }
`
