import React, { useState } from 'react'
import styled from 'styled-components'
import LoadingIndicator from '../loadingIndicator'
import { Sensor } from './Sensors'

interface SensorsProps {
  sensors: { id: string; sensor_type: string; updated_at: string }[]
  view_template: any
  offline: boolean
}

const Content = ({ sensors, view_template, offline }: SensorsProps) => {
  const [sensorsLoading, setSensorsLoading] = useState(0)

  const mainSensorTypes = Object.keys(view_template || {}).filter(
    (k: string) => view_template[k] === 'main'
  )

  const secondarySensorTypes = Object.keys(view_template || {}).filter(
    (k: string) => view_template[k] === 'secondary'
  )

  const secondarySensors = sensors.filter((s: any) =>
    secondarySensorTypes.includes(s.sensor_type)
  )

  const mainSensors = sensors.filter((s: any) =>
    mainSensorTypes.includes(s.sensor_type)
  )

  const numberOfVisibleSensors = mainSensors.length + secondarySensors.length

  return (
    <SensorsWrapper>
      {mainSensors.map((sensor) => {
        return (
          <Sensor
            setSensorsLoading={setSensorsLoading}
            key={sensor.id}
            sensorId={sensor.id}
            updated_at={sensor.updated_at}
            sensor_type={sensor.sensor_type}
            offline={offline}
          />
        )
      })}

      <SecondarySensorsWrapperStyle>
        {secondarySensors.map((sensor) => {
          return (
            <Sensor
              setSensorsLoading={setSensorsLoading}
              key={sensor.id}
              sensorId={sensor.id}
              updated_at={sensor.updated_at}
              sensor_type={sensor.sensor_type}
              secondary
              offline={offline}
            />
          )
        })}
      </SecondarySensorsWrapperStyle>

      {sensorsLoading < numberOfVisibleSensors && (
        <LoadingContainer>
          <LoadingIndicator />
        </LoadingContainer>
      )}
    </SensorsWrapper>
  )
}

export default Content

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 30px 0;
`

const SensorsWrapper = styled.div`
  position: relative;
  padding: 1rem;
`

const SecondarySensorsWrapperStyle = styled.div`
  padding-left: 50px;
`
