import { gql } from '@apollo/client'

export interface GetUserInput {
  email: string
}

export interface GetUserResponse {
  ta_user: Api.User[]
}

export const GET_USER = gql`
  query GetUser($email: String!) {
    ta_user(where: { email: { _eq: $email } }) {
      id
      full_name
      email
      user_phone
      user_language
      user_timezone
      show_locate
      show_measure
      consent_accepted
    }
  }
`
