import { useSubscription } from '@apollo/client'
import React, { useContext } from 'react'
import styled from 'styled-components'
import { MainContext } from '../../MainContext'
import {
  DevicesInput,
  DevicesResponse,
  DEVICES_SUBSCRIPTION,
} from '../../operations/subscriptions/getDevices'
import LoadingIndicator from '../loadingIndicator'
import Device from './Device'

export default function Devices() {
  const { currentCitizen } = useContext(MainContext)
  const { data, loading } = useSubscription<DevicesResponse, DevicesInput>(
    DEVICES_SUBSCRIPTION,
    {
      variables: { _citizenId: currentCitizen.id },
    }
  )

  if (loading) {
    return (
      <LoadingContainer>
        <LoadingIndicator />
      </LoadingContainer>
    )
  }

  return (
    <Container>
      {data?.ta_device.map((device) => {
        return <Device key={device.id} device={device} />
      })}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin: 20px 0 0 0;
  flex-wrap: wrap;
  justify-content: space-between;
`

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 30px 0;
`
