import React, { useState, lazy, Suspense, useMemo } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Format, getIcon } from '../helpers'
import { useTranslation } from 'react-i18next'
import TimeAgo from '../../timeago/TimeAgo'
import NormalText from './NormalText'
import nappy_line from '../../../images/nappy_line.png'
import { breakpoints } from '../../../utils/breakpoints'
import { useQuery } from '@apollo/client'
import { GET_READER_BY_ID } from '../../../operations/queries/getReaderById'

const Modal = lazy(() => import('../modals/MeasurmentsModal'))

interface SensorProps {
  sensorType: string
  measurements: Api.Measurement[]
  sensor: any
  secondary?: boolean
  offline: boolean
}

const Sensor = ({
  sensorType,
  measurements,
  sensor,
  secondary,
  offline,
}: SensorProps) => {
  const { t } = useTranslation()
  const [showModal, setShowModal] = useState(false)
  if (measurements.length <= 0) {
    return null
  }

  function toggleModal() {
    setShowModal(!showModal)
  }

  const lastMeasurement = measurements[0]
  const secondaryLabel = t(`measure.sensors.types.${sensorType}.title`, '')

  function getIconMarkup(sensorType: string) {
    if (sensorType === 'nappy') {
      return (
        !secondary && (
          <ImageContainer>
            <ImageStyle src={nappy_line} alt="Nemlia Talva" />
          </ImageContainer>
        )
      )
    }
    return !secondary && <IconStyle icon={getIcon(sensorType)} color="light" />
  }

  return (
    <Container>
      <SensorRow onClick={toggleModal} offline={offline}>
        {getIconMarkup(sensorType)}
        <div>
          <NormalText secondary={secondary}>
            {secondary && secondaryLabel && `${secondaryLabel}: `}
            {Format(sensorType, lastMeasurement.measurement_value, t)}
          </NormalText>
          <br></br>
          <LightTextStyle>
            <TimeAgo timestamp={lastMeasurement.data_timestamp} />
          </LightTextStyle>
        </div>
      </SensorRow>
      {showModal && (
        <Suspense fallback={null}>
          <Modal
            sensorType={sensorType}
            toggleModal={toggleModal}
            sensor={sensor}
          />
        </Suspense>
      )}
    </Container>
  )
}

export default Sensor

interface RfidValueProps {
  value: string
}

export function RfidValue({ value }: RfidValueProps) {
  const { t } = useTranslation()

  const { direction, reader_id } = useMemo(() => {
    try {
      return JSON.parse(value)
    } catch (e) {
      console.error(e)
      return {}
    }
  }, [value])

  const directionText = useMemo(
    () => t(`measure.sensors.types.rfid.values.${direction}`),
    [direction, t]
  )

  const { data, error } = useQuery(GET_READER_BY_ID, {
    variables: { eui: reader_id },
  })

  const reader = useMemo(() => {
    return data?.ta_device?.[0]
  }, [data])

  if (error) {
    console.error('Error: ', error.message)
  }

  const readerInfo = [reader?.room, reader?.location].filter((v) => v)

  if (readerInfo.length) {
    return `${directionText} - ${readerInfo.join(' / ')}`
  }

  return directionText
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

type SensorRowProps = {
  offline: boolean
}

const SensorRow = styled.div<SensorRowProps>`
  display: flex;
  flex-direction: row;
  padding: 10px;
  margin: 0 -10px;
  border-radius: 10px;
  align-items: center;
  cursor: pointer;
  opacity: ${(props) => (props.offline ? '0.2' : '1')};
`

type IconProps = {
  color: 'light' | 'dark'
}

const IconStyle = styled(FontAwesomeIcon)<IconProps>`
  margin: 0 20px 0 0;
  font-size: 36px;
  color: ${(props) => (props.color === 'light' ? 'white' : '#909090')};
  min-width: 30px;
  max-width: 30px;
  width: 30px;
`

const LightTextStyle = styled.span`
  font-size: 0.9rem;
  font-weight: 700;
  color: #ffffffb5;
`

const ImageStyle = styled.img`
  width: 60px;

  @media (min-width: ${breakpoints.small}px) {
    width: 60px;
    margin-left: -12px;
  }
`

const ImageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin: 10px 5px;

  @media (min-width: ${breakpoints.small}px) {
    margin: 0;
  }
`
