import { QueryResult, useQuery } from '@apollo/client'
import React, { useEffect, useState, lazy, Suspense } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import {
  GetSensorInput,
  GetSensorResponse,
  GET_OIL_INJECTOR_SENSOR,
  GET_POWER_SENSOR,
  GET_SENSOR,
} from '../../operations/queries/getSensor'
import LoadingIndicator from '../loadingIndicator'
import moment from 'moment-timezone'

const AlarmSensor = lazy(() => import('./sensors/AlarmSensor'))
const SimpleSensor = lazy(() => import('./sensors/SimpleSensor'))
const PowerSensor = lazy(() => import('./sensors/PowerSensor'))
const OilInjectorSensor = lazy(() => import('./sensors/OilInjectorSensor'))
const SmartPlug = lazy(() => import('./sensors/SmartPlug'))
const Network = lazy(() => import('./sensors/Network'))
const Battery = lazy(() => import('./sensors/Battery'))
const RfidSensor = lazy(() => import('./sensors/RfidSensor'))
const GPSSensor = lazy(() => import('./sensors/GPSSensor'))

interface SensorsProps {
  sensors: [{ id: string; sensor_type: string; updated_at: string }]
  offline: boolean
}

const Content = ({ sensors, offline }: SensorsProps) => {
  const [sensorsLoading, setSensorsLoading] = useState(0)

  return (
    <SensorsWrapper>
      {sensors.map((sensor) => {
        return (
          <SensorStyle key={sensor.id}>
            <Sensor
              setSensorsLoading={setSensorsLoading}
              sensorId={sensor.id}
              updated_at={sensor.updated_at}
              sensor_type={sensor.sensor_type}
              offline={offline}
            />
          </SensorStyle>
        )
      })}

      {sensorsLoading < sensors.length && (
        <LoadingContainer>
          <LoadingIndicator />
        </LoadingContainer>
      )}
    </SensorsWrapper>
  )
}

export default Content

interface SensorProps {
  sensorId: string
  updated_at: string
  setSensorsLoading: any
  sensor_type: string
  secondary?: boolean
  offline: boolean
}

export const Sensor = ({
  sensorId,
  updated_at,
  setSensorsLoading,
  secondary,
  sensor_type,
  offline,
}: SensorProps) => {
  switch (sensor_type) {
    case 'power':
      return (
        <PowerRedirectSensor
          updated_at={updated_at}
          setSensorsLoading={setSensorsLoading}
          sensorId={sensorId}
          secondary={secondary}
          offline={offline}
        />
      )
    case 'oil':
      return (
        <OilRedirectSensor
          updated_at={updated_at}
          setSensorsLoading={setSensorsLoading}
          sensorId={sensorId}
          secondary={secondary}
          offline={offline}
        />
      )
    default:
      return (
        <NormalRedirectSensor
          updated_at={updated_at}
          setSensorsLoading={setSensorsLoading}
          sensorId={sensorId}
          secondary={secondary}
          offline={offline}
        />
      )
  }
}

interface RedirectSensorProps {
  sensorId: string
  updated_at: string
  setSensorsLoading: any
  secondary?: boolean
  offline: boolean
}

const NormalRedirectSensor = ({
  sensorId,
  updated_at,
  setSensorsLoading,
  secondary,
  offline,
}: RedirectSensorProps) => {
  const sensorQuery = useQuery<GetSensorResponse, GetSensorInput>(GET_SENSOR, {
    variables: {
      id: sensorId,
    },
  })

  return (
    <RenderSensor
      sensorQuery={sensorQuery}
      updated_at={updated_at}
      setSensorsLoading={setSensorsLoading}
      sensorId={sensorId}
      secondary={secondary}
      offline={offline}
    />
  )
}

const OilRedirectSensor = ({
  sensorId,
  updated_at,
  setSensorsLoading,
  secondary,
  offline,
}: RedirectSensorProps) => {
  const sensorQuery = useQuery<GetSensorResponse, GetSensorInput>(
    GET_OIL_INJECTOR_SENSOR,
    {
      variables: {
        id: sensorId,
        start_time: moment().clone().startOf('month').toISOString(),
      },
    }
  )

  return (
    <RenderSensor
      sensorQuery={sensorQuery}
      updated_at={updated_at}
      setSensorsLoading={setSensorsLoading}
      sensorId={sensorId}
      secondary={secondary}
      offline={offline}
    />
  )
}

const PowerRedirectSensor = ({
  sensorId,
  updated_at,
  setSensorsLoading,
  secondary,
  offline,
}: RedirectSensorProps) => {
  const sensorQuery = useQuery<GetSensorResponse, GetSensorInput>(
    GET_POWER_SENSOR,
    {
      variables: {
        id: sensorId,
        start_time: moment().clone().startOf('month').toISOString(),
      },
    }
  )

  return (
    <RenderSensor
      sensorQuery={sensorQuery}
      updated_at={updated_at}
      setSensorsLoading={setSensorsLoading}
      sensorId={sensorId}
      secondary={secondary}
      offline={offline}
    />
  )
}

interface RenderSensorProps {
  sensorQuery: QueryResult<GetSensorResponse, GetSensorInput>
  updated_at: string
  setSensorsLoading: any
  sensorId: string
  secondary?: boolean
  offline: boolean
}

function RenderSensor({
  sensorQuery,
  updated_at,
  setSensorsLoading,
  sensorId,
  secondary,
  offline,
}: RenderSensorProps) {
  const { data, loading, error, refetch } = sensorQuery

  const { t } = useTranslation()

  useEffect(() => {
    refetch()
  }, [refetch, updated_at])

  useEffect(() => {
    if (!loading) {
      setSensorsLoading((prevState: number) => prevState + 1)
    }
  }, [loading, setSensorsLoading])

  if (loading) return null

  if (error) {
    console.error('SENSOR ERROR', error, {
      data: data,
      query: GET_SENSOR,
      sensor: sensorId,
    })
  }

  if (!data) return null

  const sensor = data.ta_sensor_by_pk

  const {
    ta_sensor_measurements: measurements,
    serial_number: serialNumber,
    sensor_settings: sensorSettings,
  } = sensor

  const sensorType = sensor.sensor_type

  switch (sensorType) {
    case 'motion':
    case 'occupancy':
    case 'button':
    case 'switch':
    case 'door':
    case 'bed':
    case 'smoke':
    case 'fire':
    case 'smoke-test':
    case 'test':
    case 'movement':
      return (
        <Suspense fallback={null}>
          <div title={t(`measure.sensors.types.${sensorType}.title`)}>
            <AlarmSensor
              secondary={secondary}
              sensor={sensor}
              sensorType={sensorType}
              measurements={measurements}
              offline={offline}
            />
          </div>
        </Suspense>
      )
    case 'dimmer':
    case 'lock':
      return (
        <Suspense fallback={null}>
          <div title={t(`measure.sensors.types.${sensorType}.title`)}>
            <SmartPlug
              sensor={sensor}
              serialNumber={serialNumber}
              sensorType={sensorType}
              measurements={measurements}
              offline={offline}
            />
          </div>
        </Suspense>
      )
    case 'networklinkaddress':
      return null
    case 'network-strength':
    case 'networklinkstrength':
      return (
        <Suspense fallback={null}>
          <Network
            measurements={measurements}
            sensor={sensor}
            offline={offline}
          />
        </Suspense>
      )
    case 'battery':
    case 'batterypercentage':
    case 'voltage':
      return (
        <Suspense fallback={null}>
          <Battery measurements={measurements} />
        </Suspense>
      )
    case 'power':
      return (
        <Suspense fallback={null}>
          <div title={t(`measure.sensors.types.${sensorType}.title`)}>
            <PowerSensor
              offline={offline}
              sensor={sensor}
              sensorType={sensorType}
              measurements={measurements}
              start={sensor.start || []}
            />
          </div>
        </Suspense>
      )
    case 'oil_injector':
      return (
        <Suspense fallback={null}>
          <div title={t(`measure.sensors.types.${sensorType}.title`)}>
            <OilInjectorSensor
              sensor={sensor}
              sensorType={sensorType}
              measurements={measurements}
              sensorSettings={sensorSettings}
              offline={offline}
            />
          </div>
        </Suspense>
      )
    case 'rfid':
      return (
        <Suspense fallback={null}>
          <div title={t(`measure.sensors.types.${sensorType}.title`)}>
            <RfidSensor
              secondary={secondary}
              sensor={sensor}
              sensorType={sensorType}
              measurements={measurements}
              offline={offline}
            />
          </div>
        </Suspense>
      )
    case 'gps':
      return (
        <Suspense fallback={null}>
          <div title={t(`measure.sensors.types.${sensorType}.title`)}>
            <GPSSensor sensorId={sensorId} />
          </div>
        </Suspense>
      )
    case 'nappy':
    default:
      return (
        <Suspense fallback={null}>
          <div title={t(`measure.sensors.types.${sensorType}.title`)}>
            <SimpleSensor
              secondary={secondary}
              sensor={sensor}
              sensorType={sensorType}
              measurements={measurements}
              offline={offline}
            />
          </div>
        </Suspense>
      )
  }
}

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 30px 0;
`

interface SensorsWrapperProps {
  secondary?: boolean
}

const SensorsWrapper = styled.div<SensorsWrapperProps>`
  position: relative;
  padding: 0 1rem;
  margin-bottom: 15px;
`

const SensorStyle = styled.div`
  margin-top: 20px;
`
