import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { Tracker } from '../../operations/subscriptions/getTrackers'
import { callApi } from '../../utils/helpers'
import styled from 'styled-components'
import { CountdownTimer } from './CountdownTimer'
import { useAuth0 } from '@auth0/auth0-react'
interface SetupProps {
  tracker: Tracker
}

export function Setup({ tracker }: SetupProps) {
  const { t } = useTranslation()

  return (
    <>
      <FontAwesomeIcon icon={faExclamationCircle} size={'2x'} />
      <p>{t('locate.gps_not_setup')}</p>
      <SetupButton tracker_id={tracker.id} />
    </>
  )
}

interface SetupButtonProps {
  tracker_id: string
}

const SetupButton = ({ tracker_id }: SetupButtonProps) => {
  const [error, setError] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()
  const { getAccessTokenSilently, isAuthenticated } = useAuth0()

  async function setup() {
    setError(null)
    setLoading(true)

    await callApi({
      url: '/tkstar/setup',
      data: { tracker_id },
      onSuccess: (response: any) => {
        if (response.status !== 200) {
          setLoading(false)
          setError(t('locate.setup_error'))
        }
        return response.json()
      },
      onError: (error: any) => {
        console.error('error', error)

        setLoading(false)
        setError(t('locate.setup_error'))
      },
      jwt: isAuthenticated ? await getAccessTokenSilently() : null,
    })
  }

  if (loading) {
    return <CountdownTimer duration={60 * 5} initialRemainingTime={60 * 5} />
  }

  return (
    <>
      <SetupButtonStyle onClick={() => setup()}>
        {t('locate.setup_gps')}
      </SetupButtonStyle>
      {!!error && <p style={{ marginTop: 36 }}>{t('locate.setup_error')}</p>}
    </>
  )
}

const SetupButtonStyle = styled.button`
  margin-top: 20px;
  background: #c053d6;
  padding: 10px;
  color: #fff;
  font-size: 24px;
  border: none;
  border-radius: 8px;
  outline: none;
  cursor: pointer;
  font-weight: bold;
  padding-right: 1rem;
  padding-left: 1rem;

  &:active {
    background: #8e34b5;
  }
`
