import React, { Component } from 'react'
import moment from 'moment-timezone'

interface Props {
  timestamp: string
}

interface State {
  timestamp: string
  timeAgo: string
  intervalId: number
}

class TimeAgo extends Component<Props, State> {
  state = {
    timestamp: '',
    timeAgo: '',
    intervalId: 0,
  }

  componentDidMount() {
    var intervalId = window.setInterval(this.calculateTimeAgo, 30000)
    this.setState({
      intervalId,
      timeAgo: moment.utc(this.props.timestamp).local().fromNow(),
    })
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId)
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.timestamp !== prevProps.timestamp) {
      this.calculateTimeAgo()
    }
  }

  calculateTimeAgo = () => {
    this.setState({
      timeAgo: moment.utc(this.props.timestamp).local().fromNow(),
    })
  }

  render() {
    return <span>{this.state.timeAgo}</span>
  }
}

export default TimeAgo
