import { faArrowUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { animateScroll } from 'react-scroll'
import styled from 'styled-components'

const ScrollToTop = () => {
  const [showScroll, setShowScroll] = useState(false)

  const offset = 280

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > offset) {
      setShowScroll(true)
    } else if (showScroll && window.pageYOffset <= offset) {
      setShowScroll(false)
    }
  }

  window.addEventListener('scroll', checkScrollTop)

  return (
    <Button
      className={showScroll ? 'visible' : ''}
      onClick={() => {
        animateScroll.scrollToTop()
      }}
    >
      <FontAwesomeIcon icon={faArrowUp}></FontAwesomeIcon>
    </Button>
  )
}

const Button = styled.a`
  position: fixed;
  bottom: 40px;
  right: 30px;
  z-index: 1000;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #444;
  color: #fff;
  box-shadow: 0 2px 10px -6px black;
  font-size: 21px;
  transition: opacity 0.3s, visibility 0.3s, bottom .4s;
  opacity: 0;
  visibility: hidden;

  &.visible {
    opacity: 1;
    visibility: visible;
    bottom: 30px;
    `

export default ScrollToTop
