import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components'

export const DashboardButton = () => {
  const location = useLocation()
  const path = location.pathname

  const active = path === '/dashboard'
  return (
    <LinkStyle active={active} aria-label="dashboard" to="/dashboard">
      <FontAwesomeIcon icon={faUser} />
    </LinkStyle>
  )
}

interface LinkStyleProps {
  active: boolean
}

export const LinkStyle = styled(Link)<LinkStyleProps>`
  font-size: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => (props.active ? 'white' : 'black')};
  background: ${(props) => (props.active ? '#27514a' : '#f7f8f8b0')};
  border-radius: 100rem;
  box-shadow: 0px 2px 6px 0px #00000045;
  transition: box-shadow 0.3s;

  width: 57.19px;
  height: 57.19px;
  margin: 3px;

  &:hover {
    box-shadow: 0px 2px 6px 0px #00000080;
  }
`
