import React, { useState } from 'react'
import styled from 'styled-components'
import { faQuestion } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ModalWindow from '../modal/Modal'
import { getInitialProps, useTranslation } from 'react-i18next'

export default function MoreInfoButton() {
  return <MoreInfo icon={faQuestion} />
}

const MoreInfo = styled(({ className, icon }) => {
  const [showModal, setShowModal] = useState(false)
  const { initialLanguage } = getInitialProps()
  const { t } = useTranslation()

  function toggleModal() {
    setShowModal(!showModal)
  }

  function diplayText(lang: string) {
    switch (lang) {
      case 'fo':
        return <TableFO />
      case 'da':
      case 'dk':
        return <TableDa />
      case 'de':
        return <TableDe />
      case 'fr':
        return <TableFr />
      default:
        return <TableEn />
    }
  }

  return (
    <>
      <div className={className} onClick={toggleModal}>
        <FontAwesomeIcon icon={icon} />
      </div>

      {showModal && (
        <ModalWindow title={t(`more_info.voc.title`)} toggleModal={toggleModal}>
          <MoreInfoContainer>{diplayText(initialLanguage)}</MoreInfoContainer>
        </ModalWindow>
      )}
    </>
  )
})`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.3rem;
  border: 2px solid #ffffff73;
  border-radius: 1rem;
  min-height: 2.9rem;
  min-width: 2.9rem;
  padding: 0.5rem;
  margin: 0 auto 15px;
  z-index: 999;
  cursor: pointer;
  transition: border 0.3s;
  color: #ffffff70;

  &:hover {
    border: 2px solid #00000040;
  }
`

const MoreInfoContainer = styled.div`
  padding: 1rem;
`

const Table = styled.table`
  border-collapse: collapse;

  td,
  th {
    border: 1px solid #999;
    padding: 0.5rem;
    text-align: left;
  }
`

const TableEn = () => {
  return (
    <>
      <p>
        Unlike outdoor air, indoor air is recycled continuously, increasing the
        concentration of compounds. VOCs are organic chemicals in indoor air
        coming from various different sources, including paint, building
        materials, glues, furnishings, tobacco smoking, as well as people and
        their activities.
        <p>
          Some VOCs can have short- and long-term negative effects on health.
        </p>
        <p> pbb = particles per billion</p>
      </p>
      <Table>
        <tr>
          <th>Level</th>
          <th>Rating</th>
          <th>Recommendation</th>
          <th>pbb/TVOC</th>
        </tr>
        <tr>
          <td>Unhealty</td>
          <td>Situation not acceptable</td>
          <td>Intense venitalation necessary</td>
          <td>10000-25000</td>
        </tr>

        <tr>
          <td>Poor</td>
          <td>Major objections</td>
          <td>Intensified venitalation/Airing necesarry</td>
          <td>3000-10000</td>
        </tr>

        <tr>
          <td>Moderate</td>
          <td>Some objections</td>
          <td>Intense venitalation recommended</td>
          <td>1000-3000</td>
        </tr>

        <tr>
          <td>Good</td>
          <td>No relevant objections</td>
          <td>Venitalation/airing recommended</td>
          <td>300-1000</td>
        </tr>

        <tr>
          <td>Excellent</td>
          <td>No objections</td>
          <td>Target value</td>
          <td>Less than 300</td>
        </tr>
      </Table>
    </>
  )
}

const TableFO = () => {
  return (
    <>
      <p>
        Luftgóðskumátarin mátar mongdina av VOC í rúminum(Total volatile organic
        compounds, TVOC) VOC eru organisk evni tilstaðar í luftini innandura.
        Summi av VOC kunnu verða orsøk til bæði stuttíðar og langtíðar
        heilsuskaðar.
      </p>
      <p>pbb = particles per billion</p>
      <Table>
        <tr>
          <th>Stig</th>
          <th>Viðmæli</th>
          <th>pbb/VOC</th>
        </tr>
        <tr>
          <td>Sera ring</td>
          <td>Sterk luftnýggjan neyðug</td>
          <td>10000-25000</td>
        </tr>

        <tr>
          <td>Ring</td>
          <td>Sterk luftnýggjan neyðug</td>
          <td>3000-10000</td>
        </tr>

        <tr>
          <td>Miðal</td>
          <td>Sterk luftnýggjan/útlufting viðmæld</td>
          <td>1000-3000</td>
        </tr>

        <tr>
          <td>Góð</td>
          <td>Útlufting viðmæld</td>
          <td>300-1000</td>
        </tr>

        <tr>
          <td>Sera góð</td>
          <td>Onki viðmæli</td>
          <td>Minni enn 300</td>
        </tr>
      </Table>
    </>
  )
}

const TableFr = () => {
  return (
    <>
      <p>
        Unlike outdoor air, indoor air is recycled continuously, increasing the
        concentration of compounds. VOCs are organic chemicals in indoor air
        coming from various different sources, including paint, building
        materials, glues, furnishings, tobacco smoking, as well as people and
        their activities.
        <p>
          Some VOCs can have short- and long-term negative effects on health.
        </p>
        <p> pbb = particles per billion</p>
      </p>
      <Table>
        <tr>
          <th>Level</th>
          <th>Rating</th>
          <th>Recommendation</th>
          <th>pbb/TVOC</th>
        </tr>
        <tr>
          <td>Unhealty</td>
          <td>Situation not acceptable</td>
          <td>Intense venitalation necessary</td>
          <td>10000-25000</td>
        </tr>

        <tr>
          <td>Poor</td>
          <td>Major objections</td>
          <td>Intensified venitalation/Airing necesarry</td>
          <td>3000-10000</td>
        </tr>

        <tr>
          <td>Moderate</td>
          <td>Some objections</td>
          <td>Intense venitalation recommended</td>
          <td>1000-3000</td>
        </tr>

        <tr>
          <td>Good</td>
          <td>No relevant objections</td>
          <td>Venitalation/airing recommended</td>
          <td>300-1000</td>
        </tr>

        <tr>
          <td>Excellent</td>
          <td>No objections</td>
          <td>Target value</td>
          <td>Less than 300</td>
        </tr>
      </Table>
    </>
  )
}

const TableDa = () => {
  return (
    <Table>
      <tr>
        <th>Level</th>
        <th>Anbefaling</th>
        <th>pbb/TVOC</th>
      </tr>
      <tr>
        <td>Meget dårlig</td>
        <td>Stærk venitalation nødvendig</td>
        <td>10000-25000</td>
      </tr>

      <tr>
        <td>Dårlig</td>
        <td>Stærk venitalation/Udluftning nødvendig</td>
        <td>3000-10000</td>
      </tr>

      <tr>
        <td>Moderat</td>
        <td>Stærk venitalation/Udluftning anbefalet</td>
        <td>1000-3000</td>
      </tr>

      <tr>
        <td>God</td>
        <td>Udluftning anbefalet</td>
        <td>300-1000</td>
      </tr>

      <tr>
        <td>Meget god</td>
        <td>Ingen anbefaling</td>
        <td>Mindre end 300</td>
      </tr>
    </Table>
  )
}

const TableDe = () => {
  return (
    <>
      <p>
        Im Gegensatz zur Außenluft wird die Innenraumluft ständig recycelt,
        wodurch sich die Konzentration der Verbindungen erhöht. VOC sind
        organische Chemikalien in der Innenraumluft, die aus verschiedenen
        Quellen stammen, z. B. aus Farben, Baumaterialien, Klebstoffen,
        Einrichtungsgegenständen, Tabakrauch sowie Menschen und ihren
        Aktivitäten.
        <p>
          Einige VOCs können kurz- und langfristig negative Auswirkungen auf die
          Gesundheit haben.
        </p>
        <p> pbb = Partikel pro Billio</p>
      </p>
      <Table>
        <tr>
          <th>Ebene</th>
          <th>Bewertung</th>
          <th>Empfehlung</th>
          <th>pbb/TVOC</th>
        </tr>
        <tr>
          <td>Ungesund</td>
          <td>Situation nicht akzeptabel</td>
          <td>Intensive Venitalisierung notwendig</td>
          <td>10000-25000</td>
        </tr>

        <tr>
          <td>Schlecht</td>
          <td>Wesentliche Einwände</td>
          <td>Intensive Venitalisierung/Beatmung notwendig</td>
          <td>3000-10000</td>
        </tr>

        <tr>
          <td>Mäßig</td>
          <td>Einige Einwände</td>
          <td>Intensive Venitalisierung empfohlen</td>
          <td>1000-3000</td>
        </tr>

        <tr>
          <td>Gut</td>
          <td>Keine relevanten Einwände</td>
          <td>Venitalisierung/Belüftung empfohlen</td>
          <td>300-1000</td>
        </tr>

        <tr>
          <td>Ausgezeichnet</td>
          <td>Keine Einwände</td>
          <td>Zielwert</td>
          <td>Weniger als 300</td>
        </tr>
      </Table>
    </>
  )
}
