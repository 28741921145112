import { IconDefinition } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { motion } from 'framer-motion'
import React from 'react'
import styled from 'styled-components'

interface Props {
  title: string
  icon: IconDefinition
}

export default function Notification({ title, icon }: Props) {
  return (
    <Container
      initial={{ y: -10, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{
        type: 'spring',
        stiffness: 300,
        damping: 20,
      }}
    >
      <Icon icon={icon}></Icon>
      {title}
    </Container>
  )
}

const Icon = styled(FontAwesomeIcon)`
  margin-right: 7px;
`

const Container = styled(motion.div)`
  background: #ca5c5c;
  padding: 20px;
  border-radius: 8px;
  color: #fff;
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 1001;
  box-shadow: 0 4px 6px -6px #000;
`
