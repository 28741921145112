import {
  faBell,
  faWalking,
  faQuestion,
  faTemperatureHigh,
  faSun,
  faWind,
  faWater,
  faCompressAlt,
  faPlug,
  faDollarSign,
  faLightbulb,
  faCloudRain,
  faDoorOpen,
  faLockOpen,
  faBolt,
  faSignal,
  faLungs,
  faBed,
  faSignature,
  faBraille,
  faBatteryEmpty,
  faBatteryThreeQuarters,
  faCouch,
  faTint,
  faToggleOn,
  faVolumeUp,
  faUserTag,
} from '@fortawesome/free-solid-svg-icons'
import { faFireSmoke, faSensorSmoke } from '@fortawesome/pro-solid-svg-icons'
import { TFunction } from 'i18next'
import { formatNumber, formatNumberFromString } from '../../utils/helpers'
import { RfidValue } from './sensors/RfidSensor'

export const convertAlarm = (sensorType: string, value: string): boolean => {
  switch (sensorType) {
    case 'occupancy':
    case 'motion':
    case 'Motion1':
    case 'Presence1':
    case 'TruePresence1':
    case 'lock':
    case 'door':
    case 'button':
    case 'fire':
    case 'smoke':
    case 'smoke-test':
    case 'test':
    case 'movement':
    case 'switch':
      if (value === 'true' || parseInt(value) === 1 || value === 'trigger')
        return true
      else return false
    case 'dimmer':
      return parseInt(value) > 0
    default:
      return false
  }
}

export const getIcon = (sensorType: string) => {
  switch (sensorType) {
    case 'button':
      return faBell
    case 'motion':
    case 'Motion1':
      return faWalking
    case 'occupancy':
    case 'TruePresence1':
    case 'Presence1':
      return faSignature
    case 'temperature':
    case 'Temperature':
      return faTemperatureHigh
    case 'luminance':
    case 'Brightness1':
      return faSun
    case 'velocity':
      return faWind
    case 'humidity':
    case 'Humidity':
      return faWater
    case 'pressure':
    case 'AirPressure':
      return faCompressAlt
    case 'switch':
      return faPlug
    case 'power':
      return faDollarSign
    case 'watts':
      return faBolt
    case 'rainfall':
      return faCloudRain
    case 'lock':
      return faLockOpen
    case 'door':
      return faDoorOpen
    case 'dimmer':
      return faLightbulb
    case 'air':
    case 'CO2':
      return faLungs
    case 'voc':
    case 'VOC':
      return faBraille
    case 'bed':
      return faBed
    case 'network-strength':
      return faSignal
    case 'battery-low':
      return faBatteryEmpty
    case 'battery':
      return faBatteryThreeQuarters
    case 'smoke':
    case 'fire':
      return faFireSmoke
    case 'smoke-test':
    case 'test':
      return faSensorSmoke
    case 'movement':
      return faCouch
    case 'input':
      return faToggleOn
    case 'Noise':
      return faVolumeUp
    case 'oil_injector':
      return faTint
    case 'rfid':
      return faUserTag
    default:
      return faQuestion
  }
}

export const Format = (
  sensorType: string,
  value: string,
  translation: TFunction,
  sensorSettings?: any
): string | [string, string] => {
  switch (sensorType) {
    case 'temperature':
    case 'Temperature':
      const temperatureValue = formatNumberFromString(value, {
        decimals: 1,
      })
      return `${temperatureValue}°`
    case 'luminance':
      return formatNumberFromString(value, {})
    case 'Brightness1':
    case 'brightness':
      return `${formatNumberFromString(value, {})} lx`
    case 'Noise':
    case 'noise':
      return `${formatNumberFromString(value, {})} dB`
    case 'velocity':
      const velocity = formatNumberFromString(value)
      return `${velocity} m/s`
    case 'humidity':
    case 'Humidity':
    case 'network-strength':
    case 'battery':
      const formattedValue = formatNumberFromString(value)
      return `${formattedValue} %`
    case 'pressure':
      const pressureValue = formatNumber(parseFloat(value) / 100, {
        decimals: 3,
      })
      return `${pressureValue} bar`
    case 'AirPressure':
    case 'airpressure':
      return `${formatNumberFromString(value)} mbar`
    case 'power':
      const kwh = convertPowerToKwh(value)
      return [
        `${formatCurrency(convertKwhToCost(kwh, kwh))} kr`,
        `${formatNumber(kwh, { decimals: 1 })} kwh`,
      ]
    case 'oil_injector':
      const liters = convertMilliSecondsToLitres(value, sensorSettings.flowRate)
      return `${formatNumber(liters, { decimals: 1 })} l`
    case 'watts':
      return `${formatNumberFromString(value)} w`
    case 'rainfall':
      return `${formatNumberFromString(value)} mm/h`
    case 'voc':
    case 'VOC':
      return `${formatNumberFromString(value)} ppb`
    case 'CO2':
    case 'co2':
      return `${formatNumberFromString(value)} ppm`
    case 'dimmer':
      return convertAlarm(sensorType, value)
        ? translation('measure.sensors.types.dimmer.on')
        : translation('measure.sensors.types.dimmer.off')
    case 'lock':
      return convertAlarm(sensorType, value)
        ? translation('measure.sensors.types.lock.on')
        : translation('measure.sensors.types.lock.off')
    case 'input':
    case 'door':
    case 'button':
    case 'motion':
    case 'Motion1':
    case 'Presence1':
    case 'TruePresence1':
    case 'occupancy':
    case 'bed':
    case 'movement':
    case 'switch':
      return convertAlarm(sensorType, value)
        ? translation(`measure.sensors.types.${sensorType}.active`)
        : translation(`measure.sensors.types.${sensorType}.not_active`)
    case 'air':
      return value
        ? translation(`measure.sensors.types.${sensorType}.values.${value}`)
        : translation('general.no_data')
    case 'rfid':
      return RfidValue({ value })
    default:
      return value
  }
}

export function convertKwhToCost(kwhToConvert: number, annualKwhUsage: number) {
  if (annualKwhUsage < 10000) {
    return kwhToConvert * 1.95
  } else if (annualKwhUsage < 100000) {
    return kwhToConvert * 1.85
  } else {
    return kwhToConvert * 1.8
  }
}

export function convertPowerToKwh(value: string) {
  return parseInt(value) / 1000
}

export function convertMilliSecondsToLitres(value: string, flowRate: string) {
  return (parseInt(value) / 1000) * (parseFloat(flowRate) / (60 * 1000))
}

export function formatCurrency(value: number): string {
  return formatNumber(value, { decimals: 2 })
}

export const AIR_QUALITY_VALUES = {
  Bad: 0,
  Poor: 1,
  Moderate: 2,
  Good: 3,
  Excellent: 4,
}

export const sensorFilterBySensorType = (
  sensors: any = [],
  filterArray: any = []
) => {
  const filtered = sensors.filter((x: any) => {
    return filterArray.includes(x.sensor_type)
  })
  const theRest = sensors.filter(
    (x: any) => !filterArray.includes(x.sensor_type)
  )

  return [filtered, theRest]
}
