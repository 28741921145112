import React, { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAuth0 } from '@auth0/auth0-react'
import { useSetUserLanguage } from '../../operations/mutations/setUserLanguage'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { getListOfLanguages } from '../../utils/helpers'

const Content = () => {
  const [selectorOpen, setSelectorOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const { i18n } = useTranslation()
  const { user } = useAuth0()
  const current_language = i18n.language
  const languages = getListOfLanguages().filter(
    (language) => language.value !== current_language
  )

  const { mutate: setUserLanguage } = useSetUserLanguage(() => {
    setLoading(false)
  })

  return (
    <Container>
      <Button disabled={loading} onClick={() => setSelectorOpen(!selectorOpen)}>
        <ButtonText>
          {loading
            ? '...'
            : getListOfLanguages().find((lang) => {
                return lang.value === current_language
              })?.label}{' '}
          <IconStyle icon={faAngleDown}></IconStyle>
        </ButtonText>
        <OtherLanguages selectorOpen={selectorOpen}>
          {languages.map((language, i) => (
            <OtherLanguage
              key={language.value}
              onClick={() => {
                i18n.changeLanguage(language.value)
                setSelectorOpen(!selectorOpen)

                setLoading(true)
                setUserLanguage({
                  variables: {
                    user_language: language.value,
                    email: user?.email,
                  },
                })
              }}
            >
              {language.label}
            </OtherLanguage>
          ))}
        </OtherLanguages>
      </Button>
    </Container>
  )
}

const Container = styled.div`
  font-size: 18px;
  margin: 0 0 20px 0;
`

const Button = styled.button`
  margin: 10px 0;
  border-radius: 10px;
  background-color: transparent;
  border: 0;
  padding: 8px 8px;
  outline: none;
  display: block;
  margin-left: auto;
`

const ButtonText = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: black;
  text-align: left;
`

type OtherLanguagesProps = {
  selectorOpen: boolean
}

const OtherLanguages = styled.ul<OtherLanguagesProps>`
  display: ${(props) => (props.selectorOpen ? `block` : `none`)};
  list-style-type: none;
  padding-left: 0pt;
  position: absolute;
  background-color: #50af9f;
  border-radius: 10px;
  padding: 4px;
  box-shadow: 0px 4px 4px -1px rgb(0 0 0 / 20%);
`

const IconStyle = styled(FontAwesomeIcon)`
  margin: 0 0 0 10px;
  font-size: 20px;
`

const OtherLanguage = styled.li`
  color: white;
  font-size: 18px;
  padding: 8px 8px;
  text-align: left;
`

export default Content
