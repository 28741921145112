import React from 'react'
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import styled from 'styled-components'

export function CountdownTimer({
  duration,
  initialRemainingTime,
}: {
  duration: number
  initialRemainingTime: number
}) {
  return (
    <CountdownCircleTimer
      initialRemainingTime={initialRemainingTime}
      strokeWidth={3}
      size={100}
      onComplete={() => {
        // do your stuff here
        return [true, 1500] // repeat animation in 1.5 seconds
      }}
      isPlaying
      duration={duration}
      colors="#c053d6"
    >
      {renderTime}
    </CountdownCircleTimer>
  )
}

const renderTime = ({ remainingTime }: { remainingTime: number }) => {
  if (remainingTime === 0) {
    return <div className="timer">&#10060;</div>
  }

  const minutes = Math.floor(remainingTime / 60)
  const seconds = remainingTime % 60

  return <Timer>{`${minutes}:${seconds}`}</Timer>
}

const Timer = styled.div`
  color: white;
  font-size: 16px;
`
