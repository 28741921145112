import { gql } from '@apollo/client'

export interface PlacesInput {
  _citizenId: string
}

interface Sensor {
  id: string
}

interface Place extends Api.Place {
  ta_sensors: Sensor[]
}

export interface PlacesResponse {
  ta_place: Place[]
}

export const PLACES_QUERY = gql`
  subscription PlacesQuery($_citizenId: uuid!) {
    ta_place(
      order_by: { title: asc }
      where: {
        ta_citizen_places: {
          ta_citizen: { id: { _eq: $_citizenId } }
          archived: { _eq: false }
        }
        archived: { _eq: false }
      }
    ) {
      id
      title
      room
      location
      ta_sensors(where: { archived: { _eq: false } }, order_by: { id: asc }) {
        id
        sensor_type
        updated_at
        ta_device {
          online
        }
      }
    }
  }
`
