import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import Map from './Map'

const Content = () => {
  const { t } = useTranslation()

  return (
    <Container id="locate">
      <HeaderStyle>
        <HeadlineStyle>{t(`locate.title`)}</HeadlineStyle>
        <IconStyle icon={faMapMarkerAlt} />
      </HeaderStyle>
      <Map />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 200px;
  background-color: #681d89;
  border-radius: 10px;
  margin: 0 5px 15px 5px;
  padding: 2rem 1rem 1rem;
  width: 100%;
  max-width: calc(100% - 10px);
  background: linear-gradient(
    212deg,
    rgb(64 14 93) 0%,
    rgb(81 35 103) 35%,
    rgb(177 14 208) 100%
  );
`

const HeaderStyle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #aaaaaa;
`

const HeadlineStyle = styled.div`
  font-size: 36px;
  font-weight: 700;
`

const IconStyle = styled(FontAwesomeIcon)`
  margin: 0;
  font-size: 36px;
`

export default Content
