import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { motion, AnimatePresence } from 'framer-motion'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { useTranslation } from 'react-i18next'

interface ModalProps {
  toggleModal: any
  title?: string
  icon?: IconDefinition
  children: any
  hideHeader?: boolean
  hideActions?: boolean
}

const ModalWindow = ({
  toggleModal,
  title,
  icon,
  hideHeader = false,
  hideActions = false,
  children,
}: ModalProps) => {
  const { t } = useTranslation()

  function clickBackdrop(e: any) {
    const targetElement = e.target
    const isBackdrop = targetElement.classList.contains('backdrop')
    if (isBackdrop) {
      toggleModal()
    }
  }

  return (
    <AnimatePresence>
      <Backdrop
        onClick={clickBackdrop}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className={'backdrop'}
      >
        <Modal
          initial={{ scale: 0.75 }}
          animate={{ scale: 1 }}
          exit={{ scale: 0 }}
        >
          {hideHeader || (
            <ModalHeader>
              {icon && <IconStyle icon={icon} color="dark" />}
              <h2>{title}</h2>
            </ModalHeader>
          )}

          {children}

          {hideActions || (
            <Actions>
              <button onClick={toggleModal}>{t('measure.close')}</button>
            </Actions>
          )}
        </Modal>
      </Backdrop>
    </AnimatePresence>
  )
}

export default ModalWindow

export const ModalHeader = styled.div`
  color: #909090;
  border-bottom: 1px solid #ccc;
  padding: 1rem;
  margin: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

type IconProps = {
  color: 'light' | 'dark'
}

export const IconStyle = styled(FontAwesomeIcon)<IconProps>`
  margin: 0 20px 0 0;
  font-size: 36px;
  color: ${(props) => (props.color === 'light' ? 'white' : '#909090')};
  min-width: 30px;
  max-width: 30px;
  width: 30px;
`

const Backdrop = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1005;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Modal = styled(motion.div)`
  width: 100%;
  max-width: 1200px;
  margin: 0 20px;
  z-index: 1010;
  background: white;
  border: 1px solid #ccc;
  cursor: normal;
  max-height: 90%;
  display: flex;
  flex-direction: column;
`

const Actions = styled.div`
  border-top: 1px solid #ccc;
  background: #eee;
  padding: 0.5rem 1rem;
  button {
    color: white;
    border: 0;
    background: #50af9f;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    font-size: 0.8rem;
    line-height: 1;
  }
`
