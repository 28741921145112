import { gql } from '@apollo/client'

export interface GetReaderByIdResponse {
  ta_device: {
    id: string
    name: string
    room: string
    location: string
  }
}

export interface GetReaderByIdResponse {
  eui: string
}

export const GET_READER_BY_ID = gql`
  query GET_READER_BY_ID($eui: String!) {
    ta_device(where: { eui: { _eq: $eui } }) {
      id
      name
      room
      location
    }
  }
`
